import { useState } from "react";
import { Loader } from "components/Loader";
import classnames from "classnames";
import styles from "./index.module.css";

interface Props {
  variant: "primary" | "secondary" | "text";
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  hoveredStyle?: React.CSSProperties;
  className?: string;
  loaderColor?: string;
}

export const Button: React.FC<Props> = ({
  variant,
  onClick,
  title,
  isLoading,
  fullWidth = true,
  disabled,
  style,
  startIcon,
  endIcon,
  hoveredStyle,
  className,
  loaderColor,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const createCn = () => {
    switch (variant) {
      case "primary":
        return styles.primary;
      case "secondary":
        return styles.secondary;
      case "text":
        return styles.text;
    }
  };

  const cn = createCn();

  return (
    <button
      className={classnames(cn, className)}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      disabled={disabled}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        ...style,
        pointerEvents: isLoading || disabled ? "none" : "auto",
        width: fullWidth && variant !== "text" ? "100%" : "fit-content",
        ...(isHovered && !isLoading && hoveredStyle),
      }}
    >
      {startIcon}
      {isLoading ? <Loader size={25} color={loaderColor} /> : title}
      {endIcon}
    </button>
  );
};
