import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { StickyContainerProps } from "./StickyContainer.types";
import styles from "./StickyContainer.module.css";
import { Box } from "@mui/material";
import useSticky from "hooks/useSticky";
import cn from "classnames";

const addedRef = (
  node: HTMLDivElement,
  ref:
    | React.ForwardedRef<HTMLDivElement>
    | React.MutableRefObject<HTMLDivElement>
) => {
  if (!ref) return;
  if (typeof ref === "function") {
    ref(node);
  } else {
    ref.current = node;
  }
};

const StickyContainer = forwardRef<HTMLDivElement, StickyContainerProps>(
  (props, ref) => {
    const {
      className,
      style,
      containerClassName,
      containerStyle,
      sx,
      sxContainer,
      onResize,
      onChange,
      children,
      top = 0,
      containerMargin = 0,
    } = props;
    const { isSticky, ref: stickyRef } = useSticky({
      topMargin: top,
      ignoreOffsetY: true,
    });
    const container = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>();
    const canSticky = height !== undefined && isSticky;

    useLayoutEffect(() => {
      if (!container.current) return;
      const stickyContainer = container.current;
      const observer = new ResizeObserver((resize) => {
        const entry = resize[0];
        setHeight(entry.contentRect.height - containerMargin);
        onResize?.(entry);
      });
      observer.observe(stickyContainer);
      setHeight(container.current.offsetHeight);
      return () => {
        observer.disconnect();
      };
    }, [containerMargin, onResize]);

    useEffect(() => {
      onChange?.(isSticky);
    }, [isSticky, onChange]);

    return (
      <Box
        ref={(node) => {
          if (!node || !(node instanceof HTMLDivElement)) return;
          const allRefs = [stickyRef, ref];
          allRefs.forEach((ref) => addedRef(node, ref));
        }}
        style={{
          ...(canSticky
            ? {
                height,
              }
            : undefined),
          ...containerStyle,
        }}
        className={cn(styles.container, containerClassName)}
        sx={sxContainer}
      >
        <Box
          ref={container}
          style={style}
          className={cn(canSticky && styles.sticky, className)}
          sx={sx}
        >
          {children}
        </Box>
      </Box>
    );
  }
);

StickyContainer.displayName = "StickyContainer";

export { StickyContainer };
