import { createTheme } from "@mui/material";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }

  interface TypographyVariants {
    text_404: CSSProperties;
    text_22: CSSProperties;
    text_21: CSSProperties;
    text_20: CSSProperties;
    text_19: CSSProperties;
    text_18: CSSProperties;
    text_17: CSSProperties;
    text_16: CSSProperties;
    text_15: CSSProperties;
    text_14: CSSProperties;
    text_13: CSSProperties;
    text_12: CSSProperties;
    text_11: CSSProperties;
    text_10: CSSProperties;
    text_9: CSSProperties;
    text_8: CSSProperties;
    text_7: CSSProperties;
    text_6: CSSProperties;
    text_5: CSSProperties;
    text_4: CSSProperties;
    text_3: CSSProperties;
    text_2: CSSProperties;
    text_1: CSSProperties;
    text_0: CSSProperties;
  }

  interface TypographyVariantsOptions {
    text_404?: CSSProperties;
  }

  interface Palette {
    white: string;
    gray: {
      b10: string;
      b25: string;
      b50: string;
      b75: string;
      b100: string;
      b200: string;
      b300: string;
      b400: string;
      b500: string;
      b600: string;
      b700: string;
      b800: string;
      b900: string;
      disabled: string;
    };
    indigo: {
      b25: string;
      b50: string;
      b200: string;
      b300: string;
      b500: string;
      b600: string;
    };
    blue: {
      b50: string;
      b100: string;
      b200: string;
      b400: string;
      b500: string;
      b600: string;
      b700: string;
    };
    blueLight: {
      b50: string;
      b100: string;
      b200: string;
      b300: string;
    };
    green: {
      b50: string;
      b500: string;
    };
    blueGray: {};
    red: {
      b200: string;
      b300: string;
      b400: string;
      b500: string;
    };
    pink: {};
    orange: {
      b50: string;
      b300: string;
      b500: string;
    };
    black: {};
    violet: {};
  }

  interface PaletteOptions {
    gray: {};
    indigo: {};
    blue: {};
    blueLight: {
      b50: string;
      b100: string;
      b200: string;
      b300: string;
    };
    green: {};
    blueGray: {};
    red: {};
    pink: {};
    orange: {};
    black: {};
    violet: {};
  }

  interface Theme {
    primary: {
      main: {
        gray: {
          0: string;
          50: string;
          75: string;
          100: string;
          200: string;
          300: string;
          400: string;
          800: string;
          900: string;
        };
      };
      brand: {
        blue: {
          100: string;
          400: string;
          gradient: string;
        };
      };
      white?: string;
      error?: {
        50?: string;
        300?: string;
        500?: string;
      };
      gray?: {
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        700?: string;
        900?: string;
      };
    };
    backgrounds?: {
      bankCard?: {
        c1?: string;
        c2?: string;
      };
    };
  }

  interface ThemeOptions {
    primary: {
      main: {
        gray: {
          0: string;
          50: string;
          75: string;
          100: string;
          200: string;
          300: string;
          400: string;
          800: string;
          900: string;
        };
      };
      brand: {
        blue: {
          100: string;
          400: string;
          gradient: string;
        };
      };
      white?: string;
      error?: {
        50?: string;
        300?: string;
        500?: string;
      };
      gray?: {
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        700?: string;
        900?: string;
        disabled: string;
      };
    };
    backgrounds?: {
      bankCard?: {
        c1?: string;
        c2?: string;
      };
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    "no-border": true;
    disabled: true;
    "no-fill": true;
  }

  interface ButtonPropsSizeOverrides {
    sm: true;
    lg: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    text_404: true;
    text_22: true;
    text_21: true;
    text_20: true;
    text_19: true;
    text_18: true;
    text_17: true;
    text_16: true;
    text_15: true;
    text_14: true;
    text_13: true;
    text_12: true;
    text_11: true;
    text_10: true;
    text_9: true;
    text_8: true;
    text_7: true;
    text_6: true;
    text_5: true;
    text_4: true;
    text_3: true;
    text_2: true;
    text_1: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Geologica", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  spacing: 1,
  shape: {
    borderRadius: 1,
  },
  primary: {
    main: {
      gray: {
        0: "rgba(255, 255, 255, 1)",
        50: "var(--main-color-bg-widgets)",
        75: "var(--main-color-bg-secondary)",
        100: "var(--main-color-border-icon)",
        200: "var(--main-color-border-icon)",
        300: "var(--main-color-text-secondary-unactive)",
        400: "var(--main-color-text-secondary)",
        800: "var(--main-color-text-title)",
        900: "var(--main-color-text-title)",
      },
    },
    brand: {
      blue: {
        100: "var(--brand-color-unactive)",
        400: "var(--brand-color-primary)",
        // gradient: "linear-gradient(146.49deg, #F1F7FF 2.88%, #DCEFF8 93.47%)",
        gradient: "var(--main-color-bg-widgets)",
      },
    },
    white: "rgba(255, 255, 255, 1)",
    error: {
      50: "var(--error-color-notification-light)",
      300: "var(--error-color-illustration)",
      500: "var(--error-color-primary)",
    },
    gray: {
      50: "var(--main-color-bg-widgets)",
      100: "var(--main-color-bg-secondary)",
      200: "var(--main-color-bg-widgets)",
      300: "var(--main-color-border-icon)",
      400: "var(--main-color-text-secondary)",
      500: "var(--main-color-text-secondary)",
      700: "var(--main-color-text-title)",
      900: "var(--main-color-text-title)",
      disabled: "var(--main-color-border-icon)",
    },
  },
  palette: {
    gray: {
      b10: "var(--main-color-text-secondary-unactive)",
      b25: "var(--main-color-bg-illustration)",
      b50: "var(--main-color-bg-widgets)",
      b75: "var(--main-color-bg-secondary)",
      b100: "var(--main-color-bg-secondary)",
      b200: "var(--main-color-border-icon)",
      b300: "var(--main-color-border-icon)",
      b400: "var(--main-color-text-secondary)",
      b500: "var(--main-color-text-secondary)",
      b600: "var(--main-color-text-secondary)",
      b700: "var(--main-color-text-subtitle)",
      b800: "var(--main-color-text-title)",
      b900: "var(--main-color-text-title)",
      disabled: "var(--main-color-border-icon)",
    },
    indigo: {
      b25: "var(--main-color-bg-widgets)",
      b50: "var(--main-color-bg-widgets)",
      b200: "var(--brand-color-disabled)",
      b300: "var(--brand-color-hover)",
      b500: "var(--brand-color-unactive)",
      b600: "var(--brand-color-primary)",
    },
    blue: {
      b50: "var(--main-color-bg-widgets)",
      b100: "var(--brand-color-unactive)",
      b200: "var(--brand-color-disabled)",
      b400: "var(--brand-color-primary)",
      b500: "var(--brand-color-unactive)",
      b600: "var(--brand-color-primary)",
      b700: "var(--main-color-bg-widgets)",
    },
    blueLight: {
      b50: "var(--main-color-bg-widgets)",
      b300: "var(--secondary-color-3)",
      b200: "var(--secondary-color-8)",
      b100: "var(--main-color-bg-widgets)",
    },
    green: {
      b50: "var(--success-color-illustration)",
      b100: "var(--success-color-icon)",
      b300: "var(--success-color-primary)",
      b500: "var(--secondary-color-5)",
    },
    blueGray: {
      b100: "var(--main-color-border-icon)",
      b200: "var(--brand-color-hover)",
      b500: "var(--brand-color-unactive)",
    },
    red: {
      b200: "var(--error-color-notification)",
      b300: "var(--error-color-illustration)",
      b400: "var(--error-color-icon)",
      b500: "var(--error-color-primary)",
    },
    pink: {
      b500: "var(--secondary-color-2)",
    },
    orange: {
      b50: "var(--warning-color-secondary-light)",
      b300: "var(--warning-color-secondary)",
      b500: "var(--warning-color-primary)",
    },
    black: "#000",
    violet: {
      b500: "var(--secondary-color-4)",
    },
  },
  backgrounds: {
    bankCard: {
      c1: "linear-gradient(90deg, #7F56D9 0%, #9E77ED 100%)",
      // c1: "var(--main-color-bg-widgets)",
      c2: "#6172F3",
    },
  },
  // shadows: {
  //   ...Array(25).fill("none"),
  //   bankCard: {
  //     s1: "0px 2px 20px rgba(97, 114, 243, 0.25)",
  //   },
  //   payments: "0px 10px 20px 4px rgba(16, 24, 40, 0.06)",
  //   officeFilter:
  //     "0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 8px 8px -4px rgba(16, 24, 40, 0.02)",
  // },
  components: {
    IconWrapper: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 44,
          height: 44,
          borderRadius: 13,
        },
      },
      variants: [
        {
          props: { variant: "wrapper" },
          style: ({ theme }: any) => ({
            backgroundColor: theme.primary.main.gray[0],
          }),
        },
        {
          props: { variant: "no-wrapper" },
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    AccountWrapper: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          columnGap: 12,
          padding: "18px 20px 18px 20px",
          maxWidth: 597,
          borderRadius: 24,
        },
      },
      variants: [
        {
          props: { variant: "dashed" },
          style: ({ theme }: any) => ({
            border: `3px dashed ${theme.primary.main.gray[100]}`,
          }),
        },
        {
          props: { variant: "filled" },
          style: ({ theme }: any) => ({
            backgroundColor: theme.primary.main.gray[50],
          }),
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxSizing: "border-box",
        },
      },

      variants: [
        {
          props: { variant: "primary" },
          style: ({ theme }) => ({
            color: theme.primary.main.gray[0],
            boxShadow: "0px 1px 2px rgba(185, 53, 71, 0.05)",
            backgroundColor: theme.primary.brand.blue[400],
            transition: ".2s",
            "& svg:not(.loader) path": {
              stroke: theme.primary.main.gray[0],
              fill: theme.primary.main.gray[0],
              transition: ".2s",
            },
            "&:hover": {
              backgroundColor: "var(--brand-color-hover)",
              color: "var(--brand-color-primary)",
              "& span svg path": {
                stroke: "var(--brand-color-primary)",
                fill: "var(--brand-color-primary)",
              },
            },
            "&:active": {
              background: "var(--brand-color-hover)",
              color: "var(--brand-color-primary)",
              "& span svg path": {
                stroke: theme.primary.brand.blue["400"],
                fill: theme.primary.brand.blue["400"],
              },
            },
            "&:disabled": {
              color: theme.primary.main.gray["400"],
              background: "var(--brand-color-disabled)",
              "& span svg:not(.loader) path": {
                stroke: theme.primary.main.gray["400"],
                fill: theme.primary.main.gray["400"],
              },
            },
          }),
        },
        {
          props: { variant: "secondary" },
          style: ({ theme }) => ({
            color: theme.primary.brand.blue[400],
            backgroundColor: theme.primary.main.gray[50],
            transition: ".2s",
            "& span svg:not(.loader) path": {
              stroke: theme.primary.brand.blue[400],
              fill: theme.primary.brand.blue[400],
              transition: ".2s",
            },
            "&:hover": {
              color: theme.primary.brand.blue[100],
              backgroundColor: theme.primary.main.gray[0],
              "& span svg path": {
                stroke: theme.primary.brand.blue[100],
                fill: theme.primary.brand.blue[100],
              },
            },
            "&:active": {
              color: theme.primary.brand.blue["100"],
              backgroundColor: theme.primary.main.gray[75],
              "& span svg path": {
                stroke: theme.primary.brand.blue["100"],
                fill: theme.primary.brand.blue["100"],
              },
            },
            "&:disabled": {
              color: theme.primary.main.gray["400"],
              backgroundColor: theme.primary.main.gray[50],
              "& span svg path": {
                stroke: theme.primary.main.gray["400"],
                fill: theme.primary.main.gray["400"],
              },
            },
          }),
        },
        {
          props: { color: "error" },
          style: ({ theme }) => ({
            color: theme.palette.red.b400,
            backgroundColor: theme.palette.red.b200,
            transition: ".2s",
            "& span svg:not(.loader) path": {
              stroke: theme.palette.red.b400,
              fill: theme.palette.red.b400,
              transition: ".2s",
            },
            "&:hover": {
              color: theme.palette.red.b400,
              backgroundColor: "var(--error-color-notification-light)",
              "& span svg path": {
                stroke: theme.palette.red.b400,
                fill: theme.palette.red.b400,
              },
            },
            "&:active": {
              color: "var(--main-color-white)",
              backgroundColor: "var(--error-color-illustration)",
              "& span svg path": {
                stroke: "var(--main-color-white)",
                fill: "var(--main-color-white)",
              },
            },
            "&:disabled": {
              color: "var(--error-color-illustration)",
              backgroundColor: "var(--error-color-notification-light)",
              "& span svg path": {
                stroke: "var(--error-color-illustration)",
                fill: "var(--error-color-illustration)",
              },
            },
          }),
        },
        {
          props: { variant: "disabled" },
          style: ({ theme }) => ({
            color: theme.primary.main.gray[400],
            backgroundColor: theme.primary.main.gray[100],
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
        },
        {
          props: { variant: "no-fill" },
          style: ({ theme }) => ({
            color: theme.primary.brand.blue[400],
            // border: `2px solid ${theme.primary.brand.blue[100]}`,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
        },
        {
          props: { size: "sm" },
          style: {
            padding: "8px 16px",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "500",
            "& span": {
              width: "20px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
        {
          props: { size: "lg" },
          style: {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "500",
            padding: "14px 16px",
            "& span": {
              width: "24px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
        {
          props: { variant: "text" },
          style: () => ({
            color: "var(--main-color-text-secondary)",
            height: "20px",
            fontSize: "14px",
            lineHeight: "20px",
            transition: ".2s",
            padding: "0",
            "&:hover": {
              backgroundColor: "transparent",
              color: "var(--main-color-text-title)",
            },
            "&:active": {
              color: "var(--brand-color-primary)",
            },
          }),
        },
      ],
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            [theme.breakpoints.up("xs")]: {
              padding: "0",
            },
            [theme.breakpoints.up("md")]: {
              padding: "14px 20px",
            },
            [theme.breakpoints.up("xl")]: {
              padding: "0 56px",
            },
          };
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            [theme.breakpoints.up("xs")]: {
              padding: ownerState.global === "true" ? "0 16px" : 0,
            },
            [theme.breakpoints.up("sm")]: {
              padding: ownerState.global === "true" ? "0 16px" : 0,
            },
            [theme.breakpoints.up("md")]: {
              padding: ownerState.global === "true" ? "0 34px" : 0,
            },
            [theme.breakpoints.up("lg")]: {
              padding: ownerState.global === "true" ? "0 86px" : 0,
            },
            [theme.breakpoints.up("xl")]: {
              padding: ownerState.global === "true" ? "0 86px" : 0,
            },
          };
        },
      },
    },
  },
});

// [ШРИФТЫ]

theme.typography.text_404 = {
  fontSize: "100px",
  lineHeight: "100px",
};

theme.typography.text_22 = {
  fontSize: "16px",
  lineHeight: "20px",
  [theme.breakpoints.up("md")]: {
    fontSize: "17px",
    lineHeight: "20px",
  },
};

theme.typography.text_21 = {
  fontSize: "13px",
  lineHeight: "14px",
};

theme.typography.text_20 = {
  fontSize: "24px",
  lineHeight: "32px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "30px",
    lineHeight: "38px",
  },
};

theme.typography.text_19 = {
  fontSize: "18px",
  lineHeight: "24px",
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
};

theme.typography.text_18 = {
  fontSize: "16px",
  lineHeight: "20px",
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};

theme.typography.text_17 = {
  fontSize: "18px",
  lineHeight: "24px",
};

theme.typography.text_16 = {
  fontSize: "16px",
  lineHeight: "20px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
};

theme.typography.text_15 = {
  fontSize: "12px",
  lineHeight: "16px",
};

theme.typography.text_14 = {
  fontSize: "20px",
  lineHeight: "28px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "30px",
    lineHeight: "38px",
  },
};

theme.typography.text_13 = {
  fontSize: "18px",
  lineHeight: "24px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
};

theme.typography.text_12 = {
  fontSize: "20px",
  lineHeight: "28px",
};

// 600
theme.typography.text_11 = {
  fontSize: "24px",
  lineHeight: "32px",
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "20px",
  },
};
// 600
theme.typography.text_10 = {
  fontSize: "16px",
  lineHeight: "20px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "18px",
    lineHeight: "24px",
  },
};
theme.typography.text_9 = {
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "-0.32px",
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
    lineHeight: "44px",
  },
};
theme.typography.text_8 = {
  fontSize: "24px",
  lineHeight: "32px",
};
// 600
theme.typography.text_7 = {
  fontSize: "20px",
  lineHeight: "28px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};
// 600
theme.typography.text_6 = {
  fontSize: "18px",
  lineHeight: "24px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};
// 500, 600wwd
theme.typography.text_5 = {
  fontSize: "16px",
  lineHeight: "20px",
};
// 400
theme.typography.text_4 = {
  fontSize: "14px",
  lineHeight: "20px",
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "20px",
  },
};
// 400, 500
theme.typography.text_3 = {
  fontSize: "14px",
  lineHeight: "20px",
};
// 500
theme.typography.text_2 = {
  fontSize: "12px",
  lineHeight: "16px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
};
// 500
theme.typography.text_1 = {
  fontSize: "12px",
  lineHeight: "16px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

theme.typography.text_0 = {
  fontSize: "10px",
  lineHeight: "12px",
};

export default theme;
