import React from "react";

export const IconAddCard = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62634 1.33301H8.37301C11.8487 1.33301 14.6663 4.15063 14.6663 7.62634V8.37301C14.6663 11.8487 11.8487 14.6663 8.37301 14.6663H7.62634C4.15063 14.6663 1.33301 11.8487 1.33301 8.37301V7.62634C1.33301 4.15063 4.15063 1.33301 7.62634 1.33301ZM8.49967 8.49968H10.6663C10.9425 8.49968 11.1663 8.27582 11.1663 7.99968C11.1663 7.72353 10.9425 7.49968 10.6663 7.49968H8.49967V5.33301C8.49967 5.05687 8.27582 4.83301 7.99967 4.83301C7.72353 4.83301 7.49967 5.05687 7.49967 5.33301V7.49968H5.33301C5.05687 7.49968 4.83301 7.72353 4.83301 7.99968C4.83301 8.27582 5.05687 8.49968 5.33301 8.49968H7.49967V10.6663C7.49967 10.9425 7.72353 11.1663 7.99967 11.1663C8.27582 11.1663 8.49967 10.9425 8.49967 10.6663V8.49968Z"
        fill="#739B67"
      />
    </svg>
  );
};
