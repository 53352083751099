export const IconSuccessVirtual = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="11" fill="white" />
    <g clipPath="url(#clip0_9481_11851)">
      <path
        d="M20 28C15.5816 28 12 24.4184 12 20C12 15.5816 15.5816 12 20 12C24.4184 12 28 15.5816 28 20C28 24.4184 24.4184 28 20 28ZM18.8488 22.8464C19.0441 23.0417 19.3607 23.0417 19.556 22.8464L24.2929 18.1088C24.6052 17.7964 24.6052 17.29 24.2928 16.9776C23.9805 16.6653 23.474 16.6653 23.1616 16.9777L19.2024 20.9376L17.5048 19.24C17.1924 18.9276 16.686 18.9276 16.3736 19.24C16.0612 19.5524 16.0612 20.0588 16.3736 20.3712L18.8488 22.8464Z"
        fill="#739B67"
      />
    </g>
    <defs>
      <clipPath id="clip0_9481_11851">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);
