import { Box, Typography } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult";
import { IconUnSuccess } from "atoms/IconUnSuccess";
import { fontWeight } from "@mui/system";

type Props = {
  onClick: () => void;
};

const TITLE = "Сервис пополнения с другой карты временно недоступен";
const SUBTITLE = "Мы уже работаем над этой проблемой. Попробуйте позже.";

export const RefillErrorScreen = ({ onClick }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
    >
      <Box marginTop="80px" height="100%" width="100%">
        <NotificationResult
          title={TITLE}
          subtitle={SUBTITLE}
          titleStyle={{
            fontSize: "20px",
            lineHheight: "24px",
          }}
          subtitleStyle={{ fontWeight: 400 }}
          icon={<IconUnSuccess />}
          height="100%"
          boxPadding="0px"
          boxWidth="100%"
          buttonsMarginTop={44}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              onClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
