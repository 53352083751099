import moment from "moment";
import { RangePickerValues } from "./RangePicker.types";
import { MONTHS } from "constants/months";

export const renderDate = (
  values: RangePickerValues,
  isFullMonth: boolean,
  isMobile: boolean
) => {
  if (isFullMonth) return MONTHS[moment(values.from).month()];

  if (isMobile) {
    const diff = moment(values.to).diff(moment(values.from), "days") + 1;

    if (diff === 14 && moment().diff(moment(values.to), "days") === 0)
      return "2 недели";
  }

  const isSameYear = values.from?.getFullYear() === values.to?.getFullYear();

  const fromLabel = moment(values.from).format(
    isSameYear ? "DD.MM" : "DD.MM.YYYY"
  );
  const toLabel = moment(values.to).format("DD.MM.YYYY");

  return `${values.from ? fromLabel : ""} ${values.to ? "-" : ""} ${
    values.to ? toLabel : ""
  }`;
};
