import {
  configureStore,
  Action,
  ThunkDispatch,
  PreloadedState,
  combineReducers,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import limitsReducer from "./slices/limitsSlice";
import historyReducer, { HistoryState } from "./slices/historySlice";
import transferSlice, { TransferState } from "./slices/transferSlice";
import cardsReducer, { CardState } from "./slices/cardsSlice";
import { authSlice, AuthInitialState } from "./slices/auth";
import { profileSlice, ProfileState } from "./slices/profile";
import { systemSlice, SystemState } from "./slices/system";
import { chatSlice, ChatState } from "./slices/chat";
import { alerts, AlertsState } from "./slices/alerts";
import { handleErrorMiddleWare } from "./slices/middlewares/handleErrorMiddleWare";
import { notificationsSlice, NotificationState } from "./slices/notifications";
import { PromotionsState, promotionsSlice } from "./slices/promotion";
import { RedirectState, redirectSlice } from "./slices/redirect";
import { virtualCardSlice } from "./slices/virtualCardSlice";

export interface IRootState {
  auth: AuthInitialState;
  profile: ProfileState;
  system: SystemState;
  transfer: TransferState;
  cards: CardState;
  chat: ChatState;
  notifications: NotificationState;
  alerts: AlertsState;
  history: HistoryState;
  promotions: PromotionsState;
  redirect: RedirectState;
}

export * from "./selectors";

const rootReducer = combineReducers({
  limits: limitsReducer,
  history: historyReducer,
  transfer: transferSlice,
  cards: cardsReducer,
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  system: systemSlice.reducer,
  chat: chatSlice.reducer,
  notifications: notificationsSlice.reducer,
  alerts: alerts.reducer,
  promotions: promotionsSlice.reducer,
  redirect: redirectSlice.reducer,
  virtualCard: virtualCardSlice.reducer,
});

export const setUpStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        handleErrorMiddleWare
      ),
  });

export const store = setUpStore();

//для асинхронного useDispatch(unwrap)
export type RootState = ReturnType<typeof rootReducer>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
