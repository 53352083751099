import { FC, useState } from "react";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  biometryRegConfirm,
  checkIsBiometryKeyExist,
  getBiometryCredentials,
  regBiometryToken,
} from "store/slices/auth";
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { useNavigate } from "react-router-dom";
import { authSelector } from "store";
import { profileActions } from "store/slices/profile";
import { useIsPWA } from "hooks/useIsPWA";
import { IconBiometryRegistration } from "atoms/IconBiometryRegistration/IconBiometryRegistration";
import { ThunkAppDispatch } from "store";
import { Button } from "components/Button";
import { PublicKeyCredentialCreationOptionsJSON } from "@github/webauthn-json/dist/types/basic/json";
import styles from "./styles.module.css";

export const Biometry: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkAppDispatch>();
  const isPWA = useIsPWA();
  const [isToken, setIsToken] = useState(false);
  const { isLoading } = useSelector(authSelector);

  const setItemToStorage = () => {
    localStorage.setItem("useBiometry", "true");
  };

  const biometryAgree = () => {
    dispatch(regBiometryToken(isPWA))
      .unwrap()
      .then((data) => {
        dispatch(getBiometryCredentials(data.registrationAddToken as string))
          .unwrap()
          .then(async (data) => {
            const message = data.publicKeyCredentialCreationOptions;
            const optionsJson = parseCreationOptionsFromJSON({
              publicKey: message as PublicKeyCredentialCreationOptionsJSON,
            });
            // @ts-ignore
            optionsJson.publicKey.extensions.appidExclude =
              // @ts-ignore
              optionsJson.publicKey.extensions.appidExclude.id;
            try {
              const credential = await create(optionsJson);
              const registrationToken = data.registrationId;
              //@ts-ignore
              dispatch(biometryRegConfirm({ registrationToken, credential }))
                .unwrap()
                .then(() => {
                  setItemToStorage();
                  navigate("/push-registration", { replace: true });
                  dispatch(profileActions.setBiometryConnected("connected"));
                })
                .catch((e) => {});
            } catch (err: any) {
              dispatch(checkIsBiometryKeyExist())
                .unwrap()
                .then((res) => {
                  if (res) {
                    setItemToStorage();
                    dispatch(profileActions.setBiometryConnected("connected"));
                  } else {
                    dispatch(profileActions.setBiometryConnected("error"));
                  }
                  navigate("/push-registration", { replace: true });
                })
                .catch((err) => {
                  if (err.response.data.code === "WRONG_DEVICE") {
                    dispatch(
                      authActions.setAnotherDeviceError({
                        title: err.response.data.title,
                        description: err.response.data.subtitle,
                      })
                    );
                    navigate("/login-error");
                  }
                });
            }
          });
      });
  };

  return (
    <div className={styles.container}>
      {isToken && (
        <Alert
          color="info"
          action={
            <Button
              variant="primary"
              onClick={() => {
                setIsToken(false);
              }}
              title="Понятно"
            />
          }
        >
          У вас уже есть ключ биометрии. Вы будете перенаправлены на главную
          страницу
        </Alert>
      )}
      <IconBiometryRegistration />
      <p className={styles.title}>
        Использовать
        <br />
        биометрические данные
        <br />
        для входа в приложение?
      </p>
      <p className={styles.description}>
        Вы сможете быстро и безопасно
        <br />
        входить в личный кабинет с помощью
        <br />
        Face и Touch ID
      </p>
      <Button
        onClick={biometryAgree}
        variant="primary"
        isLoading={isLoading}
        disabled={false}
        title="Использовать"
      />
      <Button
        onClick={() => {
          navigate("/push-registration", { replace: true });
        }}
        variant="secondary"
        isLoading={false}
        disabled={false}
        style={{ marginTop: 10 }}
        title="Напомнить позже"
      />
    </div>
  );
};
