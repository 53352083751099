import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth, ErrorParameterResponseAllOf } from "api/auth";
import {
  login,
  loginConfirm,
  logout,
  register,
  registerCheck,
  registerCVV,
  registerConfirm,
  fetchCountriesList,
  resetPassword,
  checkPassword,
  confirmResetPassword,
  checkCvv,
  regBiometryToken,
  getBiometryCredentials,
  biometryRegConfirm,
  loginError,
  createPin,
  loginPin,
  biometryAuthConfirm,
  biometryAuth,
  verifyPinCode,
  updatePinCode,
  createLogin,
} from "./asyncThunks";
import { RecoveryPasswordScreens, RegisterScreens, UserStatus } from "./model";
import { CheckBlockType } from "components/CheckBlock/CheckBlock";
import { Error } from "../profile/slice";

export enum LOGIN_ERROR_CODE {
  OTP_BLOCKED = "OTP_BLOCKED",
  ACCOUNT_LOCKED_BY_AUTH_ATTEMPTS = "ACCOUNT_LOCKED_BY_AUTH_ATTEMPTS",
  CLIENT_BLOCKED = "CLIENT_BLOCKED",
  CLIENT_UNBLOCKED = "CLIENT_UNBLOCKED",
  WRONG_REQUEST = "WRONG_REQUEST",
}

export enum REGISTER_ERROR_CODE {
  CARD_DISABLED = "CARD_DISABLED",
  WRONG_REQUEST_CARD = "WRONG_REQUEST_CARD",
  WRONG_REQUEST_PHONE = "WRONG_REQUEST_PHONE",
  WRONG_REQUEST_BIRTHDAY = "WRONG_REQUEST_BIRTHDAY",
  REGISTRATION_LOCKED_BY_ATTEMPTS = "REGISTRATION_LOCKED_BY_ATTEMPTS",
}

interface AnotherDeviceError {
  title: string;
  description: string;
}

export type AuthInitialState = {
  isLoading: boolean;
  loginToken: string;
  registerToken: string;
  registerPhone: string;
  resetToken: string;
  restoreToken: string;
  countries: any;
  isAuthenticated: boolean;
  isPinAllowed: boolean;
  isPinForgotten?: boolean;
  isConfirmed: boolean;
  registerScreen: RegisterScreens;
  resetScreen: RecoveryPasswordScreens;
  isAccountBlocked: boolean;
  accountStatus: UserStatus;
  isLoginBlocked: boolean;
  error?: Error & ErrorParameterResponseAllOf;
  accessToken?: string;
  isBurgerActive?: boolean;
  isLogoutVisible?: boolean;
  phone?: string;
  elseDevice: boolean;
  isInitialPin: boolean;
  frozenType: string;
  isCodeForgotten: boolean;
  isPinUpdated: boolean;
  anotherDeviceError: AnotherDeviceError;
  createAccountStep: CheckBlockType;
  login: string;
};

const initialState: AuthInitialState = {
  loginToken: "",
  registerToken: "",
  registerPhone: "",
  resetToken: "",
  restoreToken: "",
  accessToken: undefined,
  countries: [],
  error: undefined,
  isLoading: false,
  isAuthenticated: false,
  isPinAllowed: false,
  isPinForgotten: false,
  isConfirmed: false,
  registerScreen: "initial",
  resetScreen: "initial",
  isAccountBlocked: false,
  accountStatus: UserStatus.ACTIVE,
  isLoginBlocked: false,
  isBurgerActive: false,
  isLogoutVisible: false,
  phone: "",
  elseDevice: false,
  isInitialPin: true,
  frozenType: "registration",
  isCodeForgotten: false,
  isPinUpdated: false,
  anotherDeviceError: {
    title: "",
    description: "",
  },
  createAccountStep: "login",
  login: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNewAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    setRegisterScreen: (state, { payload }: PayloadAction<RegisterScreens>) => {
      state.registerScreen = payload;
    },
    setRestoreScreen: (
      state,
      { payload }: PayloadAction<RecoveryPasswordScreens>
    ) => {
      state.resetScreen = payload;
    },
    setAccountIsBlocked: (state, { payload }: PayloadAction<boolean>) => {
      state.isAccountBlocked = payload;
    },
    setAccountStatus: (state, { payload }: PayloadAction<UserStatus>) => {
      state.accountStatus = payload;
    },

    setLoginIsBlocked: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoginBlocked = payload;
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
      state.isConfirmed = true;
    },
    setIsPinAllowed: (state, { payload }: PayloadAction<boolean>) => {
      state.isPinAllowed = payload;
    },
    setIsPinForgotten: (state, { payload }: PayloadAction<boolean>) => {
      state.isPinForgotten = payload;
    },
    setIsCodeForgotten: (state) => {
      state.isCodeForgotten = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setIsLogoutVisible: (state) => {
      state.isLogoutVisible = !state.isLogoutVisible;
    },
    resetError: (state) => {
      state.error = undefined;
    },
    setPhone: (state, { payload }) => {
      state.phone = payload;
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.isConfirmed = false;
      state.elseDevice = false;
    },
    setElseDevice: (state, { payload }: PayloadAction<boolean>) => {
      state.elseDevice = payload;
    },
    setIsInitialPin: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitialPin = payload;
    },
    setRegisterPhone: (state, { payload }: PayloadAction<string>) => {
      state.registerPhone = payload;
    },
    resetStore: (state) => {
      state = initialState;
    },
    setIsPinUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isPinUpdated = payload;
    },
    setAnotherDeviceError: (
      state,
      { payload }: PayloadAction<AnotherDeviceError>
    ) => {
      state.anotherDeviceError = payload;
    },
    setLogin: (state, { payload }: PayloadAction<string>) => {
      state.login = payload;
    },
    setCreateAccountStep: (
      state,
      { payload }: PayloadAction<CheckBlockType>
    ) => {
      state.createAccountStep = payload;
    },
  },
  extraReducers: {
    // Authentication
    [login.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [login.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.loginToken = payload;
      state.isAuthenticated = true;
    },
    [login.rejected.type]: (state, { payload }) => {
      const error = payload?.response?.data;
      state.error = error;

      if (error?.code === LOGIN_ERROR_CODE.CLIENT_BLOCKED) {
        state.isAccountBlocked = true;
      }
      if (error?.code === LOGIN_ERROR_CODE.CLIENT_UNBLOCKED) {
        state.accountStatus = UserStatus.UNBLOCKED;
      }

      state.isLoading = false;
    },
    // Authentication - Подтверждение кода
    [loginConfirm.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [loginConfirm.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Auth>
    ) => {
      state.isLoading = false;
      state.accessToken = payload.accessToken;
      state.isConfirmed = true;
    },
    [loginConfirm.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    },
    // Authentication - Выход
    [logout.fulfilled.type]: (state) => {
      state.isAuthenticated = false;
      state.isConfirmed = false;
      state.accessToken = undefined;
    },
    [logout.rejected.type]: (state) => {
      state.isAuthenticated = false;
      state.isConfirmed = false;
      state.accessToken = undefined;
    },

    // Registration
    [fetchCountriesList.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [fetchCountriesList.fulfilled.type]: (state, { payload }) => {
      state.isLoading = false;
      state.countries = payload;
    },
    [fetchCountriesList.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
    },
    [register.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [register.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.registerToken = payload;
      state.registerScreen = "confirmation";
    },
    [register.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
      const error = payload?.response?.data;

      if (error?.code === REGISTER_ERROR_CODE.CARD_DISABLED) {
        state.registerScreen = "frozen";
        state.frozenType = "registration";
      }
    },
    // Registration - Check
    [registerCheck.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [registerCheck.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.registerScreen = "cvv";
    },
    [registerCheck.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    },
    // Registration - CVV
    [registerCVV.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [registerCVV.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.registerScreen = "createLoginAndPass";
    },
    [registerCVV.rejected.type]: (state, { payload }) => {
      const error = payload?.response?.data;
      state.isLoading = false;
      if (error?.code === "OUT_OF_CVV_COMMENT") {
        state.registerScreen = "frozen";
        state.frozenType = "cvv";
      }
      state.error = payload?.response?.data || undefined;
    },
    // Registration - Confirm
    [registerConfirm.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [registerConfirm.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.registerScreen = "success";
    },
    [registerConfirm.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    },
    // Recovery Password
    [resetPassword.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [resetPassword.fulfilled.type]: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.resetToken = payload;
      state.resetScreen = "confirmation";
    },
    [resetPassword.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
      const error = payload?.response?.data;
      if (error?.code === REGISTER_ERROR_CODE.CARD_DISABLED) {
        state.resetScreen = "frozen";
        state.frozenType = "recovery";
      }
    },
    // Reset Password - Check
    [checkPassword.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [checkPassword.fulfilled.type]: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.resetScreen = "cvv";
      state.restoreToken = payload;
    },
    [checkPassword.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    },
    // Reset Password - CVV Check
    [checkCvv.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [checkCvv.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.resetScreen = "newPassword";
      state.restoreToken = payload;
    },
    [checkCvv.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
    },
    [confirmResetPassword.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    },
    [confirmResetPassword.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.resetScreen = "success";
    },
    [confirmResetPassword.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    },
    [regBiometryToken.pending.type]: (state) => {
      state.isLoading = true;
    },
    [regBiometryToken.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [regBiometryToken.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [getBiometryCredentials.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getBiometryCredentials.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [getBiometryCredentials.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [biometryRegConfirm.pending.type]: (state) => {
      state.isLoading = true;
    },
    [biometryRegConfirm.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [biometryRegConfirm.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [loginError.pending.type]: (state) => {
      state.isLoading = true;
    },
    [loginError.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [loginError.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [createPin.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createPin.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [createPin.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [loginPin.pending.type]: (state) => {
      state.isLoading = true;
    },
    [loginPin.fulfilled.type]: (state, { payload }: PayloadAction<Auth>) => {
      state.isLoading = false;
      state.isAuthenticated = !!payload?.accessToken;
      state.accessToken = payload?.accessToken;
    },
    [loginPin.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.accessToken = undefined;
      state.error = payload?.response?.data || undefined;
    },
    [biometryAuth.pending.type]: (state) => {
      state.isLoading = true;
    },
    [biometryAuth.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.error = payload?.response?.data || undefined;
    },
    [biometryAuthConfirm.pending.type]: (state) => {
      state.isLoading = true;
      state.accessToken = undefined;
    },
    [biometryAuthConfirm.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Auth>
    ) => {
      state.isLoading = false;
      state.isAuthenticated = !!payload?.accessToken;
      state.accessToken = payload?.accessToken;
    },
    [biometryAuthConfirm.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.error = payload?.response?.data || undefined;
    },
    [verifyPinCode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [verifyPinCode.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [verifyPinCode.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      if (payload.response.status === 403 && state.accessToken) {
        state.accessToken = undefined;
      }
    },
    [updatePinCode.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updatePinCode.fulfilled.type]: (state) => {
      state.isLoading = false;
    },
    [updatePinCode.rejected.type]: (state) => {
      state.isLoading = false;
    },
    [createLogin.pending.type]: (state) => {
      state.isLoading = true;
    },
    [createLogin.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.createAccountStep = "password";
    },
    [createLogin.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    },
  },
});

export default authSlice;
