import styled from "@emotion/styled/macro";
import classNames from "classnames";
import { FocusEvent, ReactNode, useMemo, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import theme from "theme";

interface InputTextProps extends NumberFormatProps {
  label?: string;
  hint?: string;
  error?: boolean;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  variant?: "small";
}

const InputWrapper = styled.div<{ error?: boolean; variant?: "small" }>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 18px;
  background-color: ${(props) =>
    props.error
      ? "var(--error-color-notification-light)"
      : "var(--main-color-bg-widgets)"};
  padding: ${(props) =>
    props.variant === "small" ? "10px 14px" : "29px 14px"};
  gap: 10px;
  align-self: flex-start;
  box-sizing: border-box;
  input {
    font-size: ${(props) => (props.variant === "small" ? "22px" : "28px")};
    font-weight: ${(props) => (props.variant === "small" ? "400" : "500")};
    line-height: ${(props) => (props.variant === "small" ? "28px" : "36px")};
    outline: none;
    border: none;
    flex-grow: 1;
    text-overflow: ellipsis;
    background-color: transparent;
    text-align: center;
    color: var(--main-color-text-title);
    ${(props) => (props.variant === "small" ? "width: 100%;" : "")}
    width: 100%;

    &::placeholder {
      color: ${(props) =>
        props.variant === "small"
          ? "var(--main-color-text-secondary-unactive)"
          : "var(--main-color-border-icon)"};
      opacity: 1;
      font-size: ${(props) => (props.variant === "small" ? "22px" : "28px")};
      font-weight: ${(props) => (props.variant === "small" ? "400" : "500")};
      line-height: ${(props) => (props.variant === "small" ? "28px" : "36px")};
      text-align: center;
    }
  }
  &:focus-within:not(.error) {
    border-color: ${theme.palette.indigo.b300};
  }
  &.disabled {
    background-color: ${theme.palette.gray.b50};
    pointer-events: none;
    input {
      color: ${theme.palette.gray.b400};
      background-color: ${theme.palette.gray.b50};
    }
  }
`;

const InputContainer = styled.div<{ variant?: "small" }>`
  font-family: Geologica;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.variant === "small" ? "1px" : "8px")};
  width: 100%;
  position: relative;
  label {
    font-size: ${(props) => (props.variant === "small" ? "14px" : "16px")};
    font-weight: 400;
    line-height: 20px;
    color: ${(props) =>
      props.variant === "small"
        ? "var(--main-color-notification-description)"
        : "var(--main-color-text-title)"};
  }
  .hint {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.palette.gray.b500};
  }
  &.disabled {
    .hint {
      color: ${theme.palette.gray.b400};
    }
  }
  &.error {
    ${InputWrapper} {
      border-color: ${theme.palette.red.b300};
    }
    .hint {
      color: var(--error-color-icon);
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
    }
  }
`;

const SuffixImitation = styled.span`
  position: absolute;
  font-size: 28px;
  line-height: 36px;
  font-weight: 500px;
  left: calc(50% + 4px);
  color: var(--main-color-border-icon);
  pointer-events: none;
`;

export const InputNumber: React.FC<InputTextProps> = ({
  label,
  hint,
  error,
  leftNode,
  rightNode,
  disabled,
  className,
  maxLength,
  variant,
  inputMode = "decimal",
  value = "",
  onFocus,
  onBlur,
  placeholder,
  suffix,
  style,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const needDisplaySuffix = (value === "" || value === null) && isFocused;

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  return (
    <InputContainer
      className={classNames(className, { error })}
      variant={variant}
    >
      {label && <label>{label}</label>}
      <InputWrapper
        className={classNames({ disabled, error })}
        error={error}
        variant={variant}
      >
        {leftNode}
        <NumberFormat
          thousandSeparator=" "
          decimalSeparator=","
          decimalScale={2}
          allowNegative={false}
          inputMode={inputMode}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          placeholder={!isFocused ? placeholder : ""}
          suffix={suffix}
          style={{
            paddingRight: needDisplaySuffix ? 18 : undefined,
            ...style,
          }}
          {...rest}
        />
        {needDisplaySuffix && <SuffixImitation>{suffix}</SuffixImitation>}
        {rightNode}
      </InputWrapper>
      {hint && <div className="hint">{hint}</div>}
    </InputContainer>
  );
};
