import moment from "moment";

export const isFullMonthRange = (
  from: Date | string | undefined,
  to: Date | string | undefined
): boolean => {
  if (!from || !to) return false;

  const currentDayNumber = Number(moment().format("D"));
  const isCurenntMonth = moment().month() === moment(moment(to)).month();

  const diff = moment(to).diff(moment(from), "days") + 1;

  return (
    diff === moment(from).daysInMonth() ||
    (diff === currentDayNumber && isCurenntMonth)
  );
};
