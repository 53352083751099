import Modal from "@mui/material/Modal";
import {
  Container,
  Content,
  DescriptionBlock,
  Description,
  Title,
} from "./components";
import { Button } from "components/Button";

interface Props {
  isOpen: boolean;
  handler: () => void;
}

export const WarningTransferAlertMobile = ({ isOpen, handler }: Props) => {
  return (
    <Modal open={isOpen}>
      <Container>
        <Content>
          <Title>
            Внимание!
            <br /> Перевод нельзя отменить
          </Title>
          <DescriptionBlock>
            <Description>
              Проверьте внимательно реквизиты получателя, не переводите деньги
              незнакомым людям.
            </Description>
          </DescriptionBlock>
          <Button onClick={handler} title="Понятно" variant="text" />
        </Content>
      </Container>
    </Modal>
  );
};
