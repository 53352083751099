import React from "react";

export const IconChevronLeft = (props: React.SVGProps<SVGSVGElement>) => {
  const color = props?.color ?? "#739B67";

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M18 12L13 16L18 20"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
