import * as React from "react";

export const IconOtherTab = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = "25", height = "24", color = "#D3D3D3" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.69971 5.25977C3.69971 4.01713 4.70707 3.00977 5.94971 3.00977H9.03971C10.2823 3.00977 11.2897 4.01713 11.2897 5.25977V8.34976C11.2897 9.59241 10.2823 10.5998 9.03971 10.5998H5.94971C4.70707 10.5998 3.69971 9.59241 3.69971 8.34977V5.25977Z"
        fill={color}
      />
      <path
        d="M14.1097 5.25977C14.1097 4.01713 15.1171 3.00977 16.3597 3.00977H19.4497C20.6923 3.00977 21.6997 4.01713 21.6997 5.25977V8.34976C21.6997 9.59241 20.6923 10.5998 19.4497 10.5998H16.3597C15.1171 10.5998 14.1097 9.59241 14.1097 8.34977V5.25977Z"
        fill={color}
      />
      <path
        d="M3.69971 15.6598C3.69971 14.4171 4.70707 13.4098 5.94971 13.4098H9.03971C10.2823 13.4098 11.2897 14.4171 11.2897 15.6598V18.7498C11.2897 19.9924 10.2823 20.9998 9.03971 20.9998H5.94971C4.70707 20.9998 3.69971 19.9924 3.69971 18.7498V15.6598Z"
        fill={color}
      />
      <path
        d="M14.1097 15.6598C14.1097 14.4171 15.1171 13.4098 16.3597 13.4098H19.4497C20.6923 13.4098 21.6997 14.4171 21.6997 15.6598V18.7498C21.6997 19.9924 20.6923 20.9998 19.4497 20.9998H16.3597C15.1171 20.9998 14.1097 19.9924 14.1097 18.7498V15.6598Z"
        fill={color}
      />
    </svg>
  );
};
