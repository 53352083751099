import { Typography, Box } from "@mui/material";
import { useCounter } from "../../../../hooks";
import { getSecondsPluralForms } from "utils";
import { useSelector } from "react-redux";
import {
  confirmTransferToCard,
  resetTransferErrorMessage,
} from "store/slices/transferSlice";
import { retryOneTimePassword } from "store/slices/auth";
import { CodeInput } from "components/CodeInput";
import { useState } from "react";
import ClickableHeader from "./ClickableHeader";
import { useAppThunkDispatch } from "store";
import { Button } from "components/Button";

export const TransferOTP = ({ onTab }) => {
  const dispatch = useAppThunkDispatch();
  const { count, restartCounter } = useCounter();
  const [code, setCode] = useState<string>("");
  const secondsWord = getSecondsPluralForms(count);

  const {
    transfer: { createdTransfer, errorMessage },
  } = useSelector((state: any) => state);

  const handleResendCode = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  const handleClick = () => {
    dispatch(
      confirmTransferToCard({
        paymentToken: createdTransfer?.paymentToken,
        code,
      })
    )
      .unwrap()
      .then(() => {
        onTab(2);
      })
      .catch(() => {});
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <ClickableHeader onBack={() => onTab(0)} title={false} />
      <Box
        sx={{
          padding: "32px",
          boxShadow: "18px 19px 64.8px 0px #F1F1F1",
          borderRadius: "25px",
        }}
      >
        <Box sx={{ marginBottom: "4px" }}>
          <Typography
            sx={{
              fontSize: "23px",
              fontWeight: 500,
              lineHeight: "32px",
              color: "var(--main-color-text-title)",
              margin: 0,
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Подтверждение перевода
          </Typography>
          <Typography sx={{ textAlign: "center" }}>Код из SMS</Typography>
        </Box>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mb={66}
          >
            <CodeInput
              length={4}
              setCode={setCode}
              error={Boolean(errorMessage.title)}
              clearError={() => dispatch(resetTransferErrorMessage())}
            />
            {errorMessage.title && (
              <Typography
                sx={{
                  margin: 0,
                  color: "var(--error-color-icon)",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  textAlign: "center",
                }}
              >
                {errorMessage.title}
              </Typography>
            )}
          </Box>

          <Box display="flex" flexDirection="column" gap={20}>
            <Button
              disabled={code.length < 4}
              variant="primary"
              onClick={handleClick}
              title="Продолжить"
            />
            {count === 0 && (
              <Button
                onClick={handleResendCode}
                variant="text"
                style={{
                  color: "#739B67",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                title="Отправить код повторно"
              />
            )}
            {count !== 0 && (
              <Box textAlign="center" minHeight={48} mb={12}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#A9A9A9",
                  }}
                >
                  Вы сможете отправить код повторно <br /> через{" "}
                  <Typography variant="text_5" color="blue.b400">
                    {count} {secondsWord}.
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
