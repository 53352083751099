import { createStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/system";
import { CARD_STATUS } from "components/CardsInfo/model";
import { useIsMobile } from "hooks/useIsMobile";
import CardBG from "images/CardActiveBG.png";
import FrozenBG from "images/FrozenCardBG.png";
import ClosedBG from "images/ClosedCardBG.png";
import BlockedBG from "images/BlockedCardBG.png";
import VirtualBG from "images/VirtualBG.png";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setChosenCardIndex } from "store/slices/cardsSlice";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { formatCurrency } from "utils/formatCurrency";
import { RenderStatus } from "./RenderStatus";
import { IconMir } from "atoms/IconMir/IconMir";
import CardMenu from "./CardMenu";
import { CardDto } from "api/account";
import { SkyIcon } from "atoms/Sky/Sky";

type Props = {
  width?: string;
  margin?: number;
};

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    slider: {
      width: ({ width }) => width || "0px",
      overflow: "visible !important",
      margin: 0,
      paddingLeft: "10%",
      paddingTop: "10px",

      "& .swiper-pagination": {
        position: "relative",
        marginTop: "16px",
        height: 8,

        "& .swiper-pagination-bullet": {
          backgroundColor: "#D9E3CC",
          width: 8,
          height: 8,
          borderRadius: 80,
          transition: "0.5s",
          cursor: "pointer",
        },
        "& .swiper-pagination-bullet-active": {
          backgroundColor: "#739B67",
        },
      },
    },
  })
);

const renderCardBg = (card: CardDto) => {
  const isVirtualCard = card.cardType === "VIRTUAL";

  if (card.status === CARD_STATUS.Active && isVirtualCard) return VirtualBG;

  switch (card.status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
      return FrozenBG;

    case CARD_STATUS.Closed:
      return ClosedBG;

    case CARD_STATUS.Compromised:
    case CARD_STATUS.Stolen:
    case CARD_STATUS.Lost:
      return BlockedBG;

    default:
      return CardBG;
  }
};

export const CardsSlider = ({
  setIsIndex,
  allCards,
  chosenCardIndex,
  handleMenuClick,
}: {
  setIsIndex: React.Dispatch<React.SetStateAction<number>>;
  allCards: any;
  chosenCardIndex?: number;
  handleMenuClick: () => void;
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [showArrows, setShowArrows] = useState(true);

  const { isMobile } = useIsMobile();
  const classes = useStyles({ width: "80%", margin: 0 });
  const dispatch = useDispatch();

  const cardLastNumber = (cardPan: string) => {
    return cardPan?.replace(/\*/g, "");
  };

  useEffect(() => {
    if (swiperRef !== null && chosenCardIndex !== undefined) {
      //@ts-ignore
      swiperRef?.current?.swiper.slideTo(chosenCardIndex);
    }
  }, [chosenCardIndex]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        overflow="hidden"
        sx={{
          userSelect: "none",
        }}
        order={{ xs: 1, md: 2 }}
        pb={allCards.length === 1 ? "20px" : "0px"}
      >
        <Swiper
          //@ts-ignore
          ref={swiperRef}
          slidesPerView="auto"
          centeredSlides={true}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          spaceBetween={allCards.length > 0 ? 20 : 0}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          onSlideChange={(e) => {
            setIsIndex(e.realIndex);
            dispatch(setChosenCardIndex(e.realIndex));
          }}
          onSlideChangeTransitionStart={() => setShowArrows(false)}
          onSlideChangeTransitionEnd={() => setShowArrows(true)}
          className={classes.slider}
        >
          {allCards.map((card: CardDto, index: any) => {
            const isVirtualCard =
              card.cardType === "VIRTUAL" && card.status === CARD_STATUS.Active;

            const virtualCardStyles = {
              border: "2px solid #FFFFFF",
              boxShadow: "0px 4px 4px 0px #D5D4D4",
              borderRadius: "25px",
            };

            const Background = ({ src }) => (
              <img
                src={src}
                alt="ваша карта"
                style={{
                  zIndex: 0,
                  position: "absolute",
                  width: "100%",
                  borderRadius: "25px",
                }}
              />
            );

            return (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    aspectRatio: "360/200",
                    position: "relative",
                    transition: "0.5s ease transform",
                    transform:
                      chosenCardIndex === index ? "scale(1.05)" : "scale(1)",
                    ...(isVirtualCard && virtualCardStyles),
                  }}
                >
                  {card.status !== CARD_STATUS.Active && (
                    <RenderStatus
                      status={card.status}
                      isSmall={isMobile}
                      radius={25}
                    />
                  )}
                  <Background src={renderCardBg(card)} />
                  <Box
                    sx={{
                      position: "absolute",
                      padding: "24px 20px",
                      aspectRatio: "412/228",
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        display="flex"
                        flexDirection="column"
                        sx={{
                          fontSize: "32px",
                          fontWeight: 500,
                          lineHeight: "normal",
                          color: isVirtualCard
                            ? "var(--main-color-text-subtitle)"
                            : "#fff",

                          "@media (max-width: 768px)": {
                            fontSize: "28px",
                            lineHeight: "36px",
                          },
                        }}
                      >
                        {formatCurrency(card.amount as string | number, true)}
                        <Typography
                          sx={{
                            color: isVirtualCard
                              ? "var(--main-color-notification-description)"
                              : "rgba(255, 255, 255, 0.77)",
                            fontSize: "17px",
                            fontWeight: 500,
                            lineHeight: "normal",
                          }}
                        >
                          {card.description}
                        </Typography>
                      </Typography>

                      <CardMenu
                        isVirtualCard={isVirtualCard}
                        handleMenuClick={handleMenuClick}
                      />
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box display="flex" alignItems="center" gap={7}>
                        <Typography
                          sx={{
                            color: isVirtualCard
                              ? "var(--main-color-text-subtitle)"
                              : "rgba(255, 255, 255, 0.90)",
                            fontSize: "24px",
                            fontWeight: 600,
                            lineHeight: "28px",

                            "@media (max-width: 768px)": {
                              fontSize: "16px",
                              lineHeight: "20px",
                            },
                          }}
                        >
                          ••• {cardLastNumber(card.cardPan as string)}
                        </Typography>
                        {card.cardType === "VIRTUAL" && (
                          <SkyIcon
                            color={
                              card.status === "ACTIVE" ? undefined : "white"
                            }
                          />
                        )}
                      </Box>
                      <IconMir
                        color={
                          isVirtualCard
                            ? "var(--main-color-text-secondary-unactive)"
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>

      {/* arrows */}

      {!isMobile && chosenCardIndex !== 0 && showArrows && (
        <Box
          //@ts-ignore
          onClick={() => swiperRef.current?.swiper.slidePrev()}
          sx={{
            cursor: "pointer",
            position: "absolute",
            left: "10%",
            top: "45%",
            width: "28px",
            height: "48px",
            backgroundColor: "#FFF",
            borderRadius: "100px",
            zIndex: 100,
            transform: "translate(-80%, -50%)",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 5px 25.4px 0px rgba(53, 94, 41, 0.31)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M10.0986 12L5.59863 7.5L10.0986 3"
              stroke="#739B67"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      )}

      {!isMobile && chosenCardIndex !== allCards.length - 1 && showArrows && (
        <Box
          //@ts-ignore
          onClick={() => swiperRef?.current?.swiper.slideNext()}
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: "7%",
            top: "45%",
            width: "28px",
            height: "48px",
            backgroundColor: "#FFF",
            borderRadius: "100px",
            zIndex: 100,
            transform: "translate(20%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 5px 25.4px 0px rgba(53, 94, 41, 0.31)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M5.90137 4L10.4014 8.5L5.90137 13"
              stroke="#739B67"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      )}
    </Box>
  );
};
