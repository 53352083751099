import React from "react";

interface IconMenuProps extends React.SVGProps<SVGSVGElement> {}

export const IconMenu = (props: IconMenuProps) => {
  const { width = 39, height = 21, color = "white" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 39 21"
      fill="none"
    >
      <g filter="url(#filter0_b_3658_2154)">
        <rect
          y="21"
          width="21"
          height="39"
          rx="10.5"
          transform="rotate(-90 0 21)"
          fill={color}
          fillOpacity="0.4"
        />
      </g>
      <circle
        cx="12"
        cy="10.5"
        r="1.5"
        transform="rotate(-90 12 10.5)"
        fill="white"
      />
      <circle
        cx="19.5"
        cy="10.5"
        r="1.5"
        transform="rotate(-90 19.5 10.5)"
        fill="white"
      />
      <circle
        cx="27"
        cy="10.5"
        r="1.5"
        transform="rotate(-90 27 10.5)"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_3658_2154"
          x="-8.3"
          y="-8.3"
          width="55.6"
          height="37.6"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.15" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3658_2154"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3658_2154"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
