export const IconTime = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4998 18.3334C5.89984 18.3334 2.1665 14.6084 2.1665 10.0001C2.1665 5.40008 5.89984 1.66675 10.4998 1.66675C15.1082 1.66675 18.8332 5.40008 18.8332 10.0001C18.8332 14.6084 15.1082 18.3334 10.4998 18.3334ZM13.1582 13.0917C13.2582 13.1501 13.3665 13.1834 13.4832 13.1834C13.6915 13.1834 13.8998 13.0751 14.0165 12.8751C14.1915 12.5834 14.0998 12.2001 13.7998 12.0167L10.8332 10.2501V6.40008C10.8332 6.05008 10.5498 5.77508 10.2082 5.77508C9.8665 5.77508 9.58317 6.05008 9.58317 6.40008V10.6084C9.58317 10.8251 9.69984 11.0251 9.8915 11.1417L13.1582 13.0917Z"
      fill="#739B67"
    />
  </svg>
);
