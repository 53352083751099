import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";
import { HistoryModal } from "./HistoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_TABS,
  STATEMENT_TYPE_TO_TABS,
  appendExpenseList,
  appendHistoryList,
  appendIncomeList,
  getCategoryExpense,
  getCategoryIncome,
  getExpenseList,
  getHistoryChartData,
  getHistoryList,
  getIncomeList,
  getListReport,
  setActiveCategory,
  setActiveTab,
  setSelectedDay,
  setDate,
} from "store/slices/historySlice";
import { Loader, SkeletonContainer } from "components";
import {
  GetListRequestOperationFilterTypeEnum,
  StatementType,
  StatementView,
} from "api/account";
import { SearchFilters } from "./components";
import styled from "@emotion/styled/macro";
import theme from "theme";
import { parseDate } from "utils";
import { useIsMobile } from "hooks/useIsMobile";
import { FileType, downloadFile } from "utils/downloadFile";
import { EmptyScreenDefault } from "./components/EmptyScreenDefault/EmptyScreenDefault";
import { EmptyScreenCustom } from "./components/EmptyScreenCustom/EmptyScreenCustom";
import { getEndOfDay } from "utils/getEndOfDay";
import { ValueDateType } from "./components/SearchFilters/components";
import { OperationItem } from "./components/OperationItem";
import { parseTransactionsDate } from "utils/parseTransactionsDate";
import { HistoryChart } from "molecules/HistoryChart/HistoryChart";
import { Stack } from "@mui/system";
import { HistoryChartTabs } from "molecules/HistoryChartTabs/HistoryChartTabs";
import { HistoryChartTabsKeys } from "constants/chart";
import {
  generatePlaceholderData,
  getChartBarHeight,
  getChartBarWidth,
} from "molecules/HistoryChart/HistoryChart.utils";
import moment from "moment";
import { HistoryCategoryChart } from "molecules/HistoryCategoryChart/HistoryCategoryChart";
import HistoryChartHeader from "molecules/HistoryChartHeader/HistoryChartHeader";
import { useIntersectionLoad } from "hooks/useIntersectionLoad";
import { RangePicker } from "components/RangePicker/RangePicker";
import { RangePickerValues } from "components/RangePicker/RangePicker.types";
import { systemActions } from "store/slices/system";
import { ErrorBlock } from "molecules/ErrorBlock/ErrorBlock";
import { HistoryHeader } from "./components/HistoryHeader";

export const InStatementTypes: StatementType[] = [
  StatementType.CashIn,
  StatementType.OtherIn,
  StatementType.CashInExternal,
  StatementType.Salary,
  StatementType.TransferIn,
  StatementType.CashBack,
];

export type SearchFiltersValues = {
  search: string;
  fromAmount: number | null;
  toAmount: number | null;
  from: string;
  to: string;
  operationFilterType: GetListRequestOperationFilterTypeEnum | "";
  page: number;
  cardId: string;
  accId?: string;
  categoryIds?: Array<number>;
};

export const EmptyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  h2 {
    font-size: 18px;
    line-height: 24px;
    color: var(--main-color-text-secondary);
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    margin-top: 20px;
  }
  h4 {
    color: var(--main-color-text-title);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 0px;
    margin-top: 10px;
  }
`;

export const DateStyled = styled.div`
  background: var(--main-color-white);
  width: calc(100% - 20px);
  padding: 0px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-color-primary);
`;

export const AmountStyled = styled.span<{ isIncoming: boolean }>`
  color: ${(props) =>
    props.isIncoming ? theme.palette.blue.b400 : theme.palette.gray.b800};
  margin-left: auto;
  white-space: nowrap;
  &:before {
    content: "${(props) => (props.isIncoming ? "+" : "")}";
  }
`;

const HistoryWrapper = styled.div<{
  isMobile: boolean;
  isEmptyScreen: boolean;
}>`
  position: relative;
  overflow-x: ${(props) => (props.isMobile ? "hidden" : "inherit")};
  overflow-y: visible;
  height: ${(props) => (props.isEmptyScreen ? "calc(100svh - 50px)" : "100%")};
`;

const DashboardWrapper = styled.div`
  padding: 12px 20px 8px 20px;
  background-color: ${theme.primary.gray?.[200]};
  border-radius: 16px;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    padding: 12px 16px 8px 16px;
    margin-bottom: 8px;
  }
`;

const History = () => {
  const dispatch = useDispatch();
  const {
    history: {
      historyList,
      isLoading: isApiLoading,
      activeTab,
      expenseList,
      incomeList,
      isReportLoading,
      activeCategoryId,
      categoriesData,
      categoriesExpenseSum,
      categoriesIncomeSum,
      historyChartData,
      isCategoriesDataLoading,
      isHistoryChartDataLoading,
      showMobileFinancialAnalysisPage,
      selectedDay,
      isChartError,
      date: { to },
    },
    cards,
    system: { activeTabState },
  } = useSelector((state: any) => state);

  const data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  const [isSearchVisible, setSearchVisible] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });
  const { isMobile } = useIsMobile();
  const lastBlockRef = useRef<HTMLDivElement | null>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);

  const dateNow = new Date();
  const fromDate = isMobile
    ? moment().add(1, "day").subtract(2, "week")
    : moment().utc().startOf("month");

  const [chartDateValues, setChartDateValues] = useState<ValueDateType>({
    from: fromDate.toISOString(),
    to: getEndOfDay(dateNow),
  });

  const initialState = {
    search: "",
    fromAmount: null,
    toAmount: null,
    from: fromDate.toISOString(),
    to,
    operationFilterType: "" as GetListRequestOperationFilterTypeEnum | "",
    page: 0,
    cardId: activeTabState.cardId || "",
    acctId: cards.account.accountNumber,
    categoryIds: undefined,
  };

  const [searchFilters, setSearchFilters] =
    useState<SearchFiltersValues>(initialState);
  const [chartTab, setChartTab] = useState<HistoryChartTabsKeys>(
    HistoryChartTabsKeys.Days
  );

  const payload = useMemo(
    () => ({
      size: 10,
      ...searchFilters,
      ...{
        operationFilterType: searchFilters.operationFilterType || null,
      },
    }),
    [searchFilters]
  );

  const fromTextFilter = parseDate(payload.from);
  const toTextFilter = parseDate(payload.to);
  const fromTextDefault = parseDate(fromDate.toISOString());
  const toTextDefault = parseDate(dateNow.toISOString());

  const isDefaultFilters =
    !payload.search.length &&
    !payload.cardId.length &&
    !payload.fromAmount &&
    !payload.toAmount &&
    fromTextFilter === fromTextDefault &&
    toTextFilter === toTextDefault;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isChanged, setChanged] = useState<boolean>(false);

  const historyChartStatements =
    !isHistoryChartDataLoading && historyChartData.statements?.length
      ? historyChartData.statements
      : generatePlaceholderData(chartDateValues.from, chartDateValues.to);

  const onChangeChartTab = (e: SyntheticEvent, value: HistoryChartTabsKeys) => {
    setChartTab(value);

    if (value === HistoryChartTabsKeys.Categories) {
      dispatch(setSelectedDay(null));
    } else {
      dispatch(setActiveCategory(null));
    }

    setSearchFilters((prev) => ({
      ...prev,
      categoryIds: undefined,
      ...chartDateValues,
      page: 0,
    }));
    setLoading(true);
  };

  const getChartTypeByActiveTab = () => {
    if (activeTab === HISTORY_TABS.EXPENSES) return "expense";
    if (activeTab === HISTORY_TABS.INCOMES) return "income";
  };

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const handleLoadMore = () => {
    const len = data[activeTab].reduce(
      (acc, it) => it.statements.length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === searchFilters.page;

    if (!isSamePage && isEndOfSlice) {
      setSearchFilters({
        ...searchFilters,
        page: Math.floor(len / 10),
      });
    }
  };

  const checkChanged = (newDate: ValueDateType) => {
    const isValuesChanged =
      JSON.stringify({
        ...initialState,
        from: "",
        to: "",
      }) !==
      JSON.stringify({
        ...searchFilters,
        from: "",
        to: "",
      });

    let isDateChanged = false;
    const newDateWithoutTime = newDate.from.slice(0, 10);
    const fromDateWithoutTime = fromDate.toISOString().slice(0, 10);

    if (
      newDateWithoutTime !== fromDateWithoutTime ||
      newDate.to !== getEndOfDay(dateNow)
    ) {
      isDateChanged = true;
    }

    setChanged(isValuesChanged || isDateChanged);
  };

  const handleSearch = () => {
    setLoading(true);
    setSearchFilters({ ...searchFilters, page: 0 });
    checkChanged({ from: searchFilters.from, to: searchFilters.to });
  };

  const handleClearAmount = () => {
    setSearchFilters({
      ...searchFilters,
      fromAmount: null,
      toAmount: null,
      page: 0,
    });
    setLoading(true);
    setChanged(false);
  };

  const handleClearCard = () => {
    if (activeTabState.cardId) dispatch(systemActions.clearActiveTabState());
    setSearchFilters({
      ...searchFilters,
      cardId: "",
      page: 0,
    });
    setLoading(true);
    setChanged(false);
  };

  const handleChangeCard = useCallback(
    (cardId: string) => {
      setSearchFilters({
        ...searchFilters,
        cardId,
        page: 0,
      });
      setLoading(true);
      setChanged(false);
    },
    [searchFilters]
  );

  const handleReset = (fullReset: boolean) => {
    if (fullReset) {
      if (activeTabState.cardId) dispatch(systemActions.clearActiveTabState());
      setChartDateValues({
        from: fromDate.toISOString(),
        to: getEndOfDay(dateNow),
      });

      dispatch(
        setDate({
          from: fromDate.toISOString(),
          to: getEndOfDay(dateNow),
        })
      );
    }

    dispatch(setActiveCategory(null));
    setSearchFilters(
      fullReset
        ? {
            ...initialState,
            from: fromDate.toISOString(),
            to: getEndOfDay(dateNow),
            categoryIds: undefined,
          }
        : { ...searchFilters, categoryIds: undefined, search: "", page: 0 }
    );
    setLoading(true);
    setChanged(false);
  };

  const handleDownload = () => {
    if (isReportLoading) return;
    const operationViewType = {
      [HISTORY_TABS.ALL_OPERATIONS]: StatementView.All,
      [HISTORY_TABS.EXPENSES]: StatementView.Expense,
      [HISTORY_TABS.INCOMES]: StatementView.Income,
    };
    dispatch(
      // @ts-ignore
      getListReport({
        ...payload,
        operationViewType: operationViewType[activeTab],
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res) => downloadFile(res, "report.xls", FileType.EXCEL));
  };

  const onSelectDay = useCallback(
    (date: string | null) => {
      dispatch(setSelectedDay(date));
    },
    [dispatch]
  );

  const onSelectCategory = useCallback(
    (id: number | null) => {
      dispatch(setActiveCategory(id));
    },
    [dispatch]
  );

  const reset = () => {
    dispatch(setSelectedDay(null));
    dispatch(setActiveCategory(null));
    setSearchFilters((prev) => ({
      ...prev,
      operationFilterType: "",
      categoryIds: [],
      ...chartDateValues,
      page: 0,
    }));
  };

  const onFetchChartData = () => {
    if (
      chartTab === HistoryChartTabsKeys.Days ||
      activeTab === HISTORY_TABS.ALL_OPERATIONS
    ) {
      dispatch(
        getHistoryChartData({
          ...payload,
          ...chartDateValues,
          statementView: STATEMENT_TYPE_TO_TABS[activeTab],
        })
      );
    } else {
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(getCategoryExpense(payload));
      }

      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(getCategoryIncome(payload));
      }
    }
  };

  const onChangeFilterValue = (filterUpdate: Partial<SearchFiltersValues>) => {
    setSearchFilters({ ...searchFilters, ...filterUpdate });
  };

  const onChangeDateFilters = (values: Required<RangePickerValues>) => {
    const rangeValues = {
      from: values.from?.toISOString(),
      to: values.to.toISOString(),
    };

    dispatch(setActiveCategory(null));
    setSearchFilters((prev) => ({
      ...prev,
      ...rangeValues,
      categoryIds: [],
      page: 0,
    }));
    setChartDateValues(rangeValues);
    setLoading(true);
    checkChanged(rangeValues);

    if (selectedDay) {
      onSelectDay(null);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.search.length, searchFilters.operationFilterType]);

  useEffect(() => {
    if (chartTab !== HistoryChartTabsKeys.Days) return;

    const newFilters = {
      ...searchFilters,
      page: 0,
      ...(selectedDay
        ? {
            from: moment(selectedDay).startOf("day").utc(true).toISOString(),
            to: moment(selectedDay).endOf("day").utc(true).toISOString(),
          }
        : {
            from: String(chartDateValues?.from),
            to: String(chartDateValues?.to),
          }),
    };

    setSearchFilters(newFilters);
    setLoading(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  useEffect(() => {
    if (isLoading) {
      if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        // @ts-ignore
        dispatch(getHistoryList(payload));
      }
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(getExpenseList(payload));
      }
      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(getIncomeList(payload));
      }
      checkChanged({ from: payload.from, to: payload.to });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchFilters.search.length]);

  useEffect(() => {
    onFetchChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, chartDateValues.from, chartDateValues.to, chartTab]);

  useEffect(() => {
    if (searchFilters.page > 0) {
      if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        // @ts-ignore
        dispatch(appendHistoryList(payload));
      }
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(appendExpenseList(payload));
      }
      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(appendIncomeList(payload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.page, activeTab]);

  useEffect(() => {
    if (!showMobileFinancialAnalysisPage) {
      if (!activeCategoryId && searchFilters.categoryIds && !isMobile) {
        setSearchFilters((prev) => ({
          ...prev,
          categoryIds: undefined,
          page: 0,
        }));
        if (activeTab === HISTORY_TABS.EXPENSES) {
          dispatch(
            // @ts-ignore
            getExpenseList({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
        }
        if (activeTab === HISTORY_TABS.INCOMES) {
          dispatch(
            // @ts-ignore
            getIncomeList({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
        }
      }

      if (
        !activeCategoryId &&
        searchFilters.operationFilterType &&
        HISTORY_TABS.ALL_OPERATIONS
      ) {
        setSearchFilters((prev) => ({
          ...prev,
          operationFilterType: "",
          page: 0,
        }));
      }

      if (activeCategoryId && activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        const operationFilterType =
          activeCategoryId === 1
            ? GetListRequestOperationFilterTypeEnum.WriteOffs
            : GetListRequestOperationFilterTypeEnum.Incomes;

        setSearchFilters((prev) => ({
          ...prev,
          operationFilterType,
          page: 0,
        }));

        dispatch(
          // @ts-ignore
          getHistoryList({
            ...payload,
            operationFilterType,
            page: 0,
          })
        );
      }

      if (
        activeCategoryId &&
        activeCategoryId !== searchFilters.categoryIds &&
        activeTab !== HISTORY_TABS.ALL_OPERATIONS
      ) {
        const activeCategory =
          categoriesData.filter(
            (category) => category.id === activeCategoryId
          )[0].filterCategories || [];

        setSearchFilters((prev) => ({
          ...prev,
          categoryIds: [...activeCategory],
          page: 0,
        }));
        if (activeTab === HISTORY_TABS.EXPENSES) {
          dispatch(
            // @ts-ignore
            getExpenseList({
              ...payload,
              categoryIds: [...activeCategory],
              page: 0,
            })
          );
        }
        if (activeTab === HISTORY_TABS.INCOMES) {
          dispatch(
            // @ts-ignore
            getIncomeList({
              ...payload,
              categoryIds: [...activeCategory],
              page: 0,
            })
          );
        }
      }
    }
    if (activeCategoryId) {
      setChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    if (!showMobileFinancialAnalysisPage) {
      handleReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!isApiLoading) setLoading(isApiLoading);
  }, [isApiLoading]);

  useEffect(() => {
    return () => {
      dispatch(systemActions.clearActiveTabState());
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
      dispatch(
        setDate({
          from: fromDate.toISOString(),
          to: getEndOfDay(dateNow),
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      activeTabState.cardId &&
      (!searchFilters.cardId || activeTabState.cardId !== searchFilters.cardId)
    ) {
      handleChangeCard(activeTabState.cardId);
    }
  }, [activeTabState.cardId, handleChangeCard, searchFilters.cardId]);

  useIntersectionLoad({
    targetRef: lastBlockRef,
    loadMore: handleLoadMore,
    isLoading,
  });

  return (
    <HistoryWrapper
      isMobile={isMobile}
      isEmptyScreen={!isLoading && isMobile && !data[activeTab].length}
    >
      <HistoryHeader handleDownload={handleDownload} reset={reset} />

      <DashboardWrapper ref={dashboardRef}>
        <Stack
          mb={{
            lg: 12,
            xs: 8,
          }}
          flexDirection="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <HistoryChartTabs
            value={chartTab}
            onChange={onChangeChartTab}
            isMobile={isMobile}
          />

          <RangePicker
            onChange={onChangeDateFilters}
            values={{
              from: new Date(chartDateValues.from),
              to: new Date(chartDateValues.to),
            }}
            isDisabled={
              chartTab === HistoryChartTabsKeys.Categories
                ? isCategoriesDataLoading
                : isHistoryChartDataLoading
            }
          />
        </Stack>

        <HistoryChartHeader
          currencySign={historyChartData.currency?.sign}
          incomeSum={
            chartTab === HistoryChartTabsKeys.Categories
              ? categoriesIncomeSum
              : historyChartData.statistic?.incomeSum
          }
          expenseSum={
            chartTab === HistoryChartTabsKeys.Categories
              ? categoriesExpenseSum
              : historyChartData.statistic?.expenseSum
          }
          chartType={getChartTypeByActiveTab()}
          isLoading={
            activeTab === HISTORY_TABS.ALL_OPERATIONS ||
            chartTab === HistoryChartTabsKeys.Days
              ? isHistoryChartDataLoading
              : isCategoriesDataLoading
          }
          marginBottom={12}
        />

        {isChartError ? (
          <ErrorBlock onRefresh={onFetchChartData} />
        ) : (
          <>
            {chartTab === HistoryChartTabsKeys.Categories ? (
              <SkeletonContainer
                isLoading={
                  activeTab === HISTORY_TABS.ALL_OPERATIONS
                    ? isHistoryChartDataLoading
                    : isCategoriesDataLoading
                }
                width="100%"
                height={132}
              >
                <HistoryCategoryChart
                  selectedCategoryId={activeCategoryId}
                  onSelectCategory={onSelectCategory}
                  data={categoriesData}
                  currencySign={historyChartData.currency?.sign}
                />
              </SkeletonContainer>
            ) : (
              <HistoryChart
                chartType={getChartTypeByActiveTab()}
                onSelect={onSelectDay}
                currencySign={historyChartData.currency?.sign}
                isMobile={isMobile}
                isLoading={isHistoryChartDataLoading}
                data={historyChartStatements}
                barWidth={getChartBarWidth(isMobile)}
                barHeight={getChartBarHeight(isMobile)}
                containerRef={dashboardRef}
                incomeSum={historyChartData.statistic?.incomeSum || 0}
                expenseSum={historyChartData.statistic?.expenseSum || 0}
                selectedDate={selectedDay}
              />
            )}
          </>
        )}
      </DashboardWrapper>

      <>
        <SearchFilters
          filterValues={searchFilters}
          cards={[cards.mainCard, ...cards.anotherCards]}
          onFilterChange={onChangeFilterValue}
          onSearch={handleSearch}
          onReset={handleReset}
          onClearAmount={handleClearAmount}
          onClearCard={handleClearCard}
          onChangeCard={handleChangeCard}
          isMobile={isMobile}
          isChanged={isChanged}
          isLoading={isApiLoading}
          isSearchVisible={isSearchVisible}
          setSearchVisible={setSearchVisible}
        />

        <SkeletonContainer height="570px" isLoading={isApiLoading} width="100%">
          {!isLoading &&
            (data[activeTab].length ? (
              <div>
                {/*@ts-ignore*/}
                {data[activeTab].map((item) => {
                  if (!item.statements.length) return null;
                  return (
                    <Box key={item.date} mb={8}>
                      <DateStyled>
                        {parseTransactionsDate(item.date || "")}
                      </DateStyled>
                      <Box display="flex" flexDirection="column">
                        {/*@ts-ignore*/}
                        {item.statements.map((el) => (
                          <OperationItem
                            key={el.statementId}
                            item={el}
                            onOpenModal={openModal}
                          />
                        ))}
                      </Box>
                    </Box>
                  );
                })}
                {isLoading && (
                  <Box
                    width="100%"
                    height={15}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Loader size={15} />
                  </Box>
                )}
                <div ref={lastBlockRef} />
              </div>
            ) : isDefaultFilters ? (
              <EmptyScreenDefault />
            ) : (
              <EmptyScreenCustom />
            ))}
          {modalStatus.isOpen && (
            <HistoryModal
              isOpen={modalStatus.isOpen}
              onClose={closeModal}
              historyId={modalStatus.historyId}
            />
          )}
        </SkeletonContainer>
      </>
    </HistoryWrapper>
  );
};

export default History;
