import { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import RadioButton from "components/RadioButton/RadioButton";

type Props = {
  payload: {
    id?: number;
    title?: string;
    actionType?: string;
    srcJpg?: string;
    icon?: ReactNode;
    iconMobile?: ReactNode;
    subtitle?: string;
    subtitleMob?: string;
    textBtn?: string;
    textAlign?: string;
  };
  foreverBlock?: boolean;
  setBlockTypeAction?: (val: string) => void;
};
export const BlockConfirm = ({
  payload,
  foreverBlock,
  setBlockTypeAction,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<"LOST" | "STOLEN" | null>(
    null
  );

  const { isMobile } = useIsMobile();
  const changeCause = (event: any) => {
    if (setBlockTypeAction) {
      setSelectedValue(event.target.value);
      setBlockTypeAction(event.target.value);
    }
  };

  const icon = isMobile ? payload.iconMobile : payload?.icon;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mt={64}>
        {payload?.icon ? (
          icon
        ) : (
          <img
            src={payload?.srcJpg}
            alt="картинка"
            style={{
              width: 426,
              height: 150,
              maxWidth: "100%",
            }}
          />
        )}
      </Box>
      <Typography
        variant="text_8"
        color="var(--main-color-text-title)"
        fontWeight={500}
        mb={8}
        mt={22}
        sx={{
          textAlign: payload.textAlign ? payload.textAlign : "center",
          color: "#393E46",
          fontSize: "28px",
          fontWeight: 500,
          lineHeight: "36px",
        }}
      >
        {payload?.title}?
      </Typography>

      <Typography
        style={{ hyphens: "auto" }}
        sx={{
          textAlign: payload.textAlign ? payload.textAlign : "center",
          color: "#393E46",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        {payload?.subtitle}
      </Typography>

      {foreverBlock && (
        <div style={{ marginTop: 24 }}>
          <Box py={16}>
            <RadioButton
              changed={changeCause}
              isSelected={selectedValue === "STOLEN"}
              label="Карта была украдена"
              value="STOLEN"
            />
          </Box>
          <Box py={16}>
            <RadioButton
              changed={changeCause}
              isSelected={selectedValue === "LOST"}
              label="Карта была утеряна"
              value="LOST"
            />
          </Box>
        </div>
      )}
    </Box>
  );
};
