import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { IconBlocked } from "atoms/IconBlocked";
import { NotificationResult } from "molecules/NotificationResult";
import { authActions } from "store/slices/auth";
import { hideErrorMessage } from "store/slices/alerts";

const BlockedScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToStartPage = () => {
    dispatch(hideErrorMessage());
    dispatch(authActions.resetError());
    dispatch(authActions.setRegisterScreen("initial"));
    navigate("/sign-in");
  };

  useEffect(() => {
    dispatch(hideErrorMessage());
  }, [dispatch]);

  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      container
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <NotificationResult
        pageTitle="Карта заблокирована"
        title="Ваша карта заблокирована"
        subtitle={`Для получения новой карты обратитесь\n в обслуживающее полевое учреждение`}
        subtitleSize="text_5"
        buttons={[
          {
            name: "Вернуться на страницу входа",
            variant: "primary",
            onClick: goToStartPage,
          },
        ]}
        icon={<IconBlocked />}
      />
    </Grid>
  );
};

export default BlockedScreen;
