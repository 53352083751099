import { useEffect, useRef, useState } from "react";
import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import { ConfigProvider, DatePicker } from "antd";
import locale from "antd/locale/ru_RU";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "./styles.css";
import { useIsMobile } from "hooks/useIsMobile";

dayjs.locale("ru");

interface Props {
  onChange: (value: string | string[]) => void;
  placeholder?: string;
  error?: boolean;
}

export const DateInput = ({ onChange, placeholder, error }: Props) => {
  const { isMobile } = useIsMobile();
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChange = (_, value: string | string[]) => {
    onChange(value);
  };

  useEffect(() => {
    if (datePickerRef.current) {
      const inputElement = datePickerRef.current.querySelector("input");
      if (inputElement) {
        inputElement.setAttribute("readOnly", "true");
      }
    }
  }, []);

  return (
    <div
      ref={datePickerRef}
      style={{
        borderRadius: "16px",
        border: error ? "1px solid var(--error-color-illustration)" : "none",
      }}
    >
      <ConfigProvider locale={locale}>
        <DatePicker
          open={isOpen}
          onOpenChange={setIsOpen}
          onChange={handleChange}
          placeholder={placeholder || ""}
          suffixIcon={
            <IconCalendar
              onClick={() => setIsOpen((prevState) => !prevState)}
            />
          }
          showNow={false}
          format="DD.MM.YYYY"
          style={{
            width: "100%",
            borderRadius: "16px",
            height: isMobile ? "56px" : "64px",
            padding: "0px 16px",
            background: isOpen
              ? "var(--brand-color-disabled)"
              : "var(--main-color-bg-widgets)",
            color: "var(--main-color-text-title)",
            border: "none",
            cursor: "text",
          }}
        />
      </ConfigProvider>
    </div>
  );
};
