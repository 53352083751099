import React, { ReactElement } from "react";
import { Box, useTheme, Typography, Theme } from "@mui/material";
import { IconClose } from "atoms/IconClose/IconClose";
import IconInfo from "atoms/IconInfo/IconInfo";
import { useIsMobile } from "hooks/useIsMobile";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { IconAlertTriangle } from "atoms/IconAlertTriangle/IconAlertTriangle";

type Type = "info" | "error";

interface Props {
  type: Type;
  title: string;
  description?: string;
  onClose?: () => void;
  withInfoIcon?: boolean;
  borderRadius?: number;
}

const getStylesByType = (type: Type, theme: Theme) => {
  const { blue, gray, blueLight, red } = theme.palette;
  const {
    primary: { error },
  } = theme;

  const styles: Record<
    Type,
    {
      bgColor?: string;
      borderColor?: string;
      titleColor?: string;
      descriptionColor?: string;
      closeIconColor?: string;
    }
  > = {
    info: {
      bgColor: blueLight.b50,
      borderColor: "none",
      titleColor: gray.b900,
      descriptionColor: gray.b700,
      closeIconColor: gray.b900,
    },
    error: {
      bgColor: "#FFF4F4",
      borderColor: error?.[50],
      titleColor: "#B72525",
      descriptionColor: "#454A3F",
      closeIconColor: red.b500,
    },
  };

  return styles[type];
};

export const Notification: React.FC<Props> = ({
  type = "info",
  title,
  description,
  onClose,
  withInfoIcon,
  borderRadius = 0,
}) => {
  const theme = useTheme();
  const { bgColor, borderColor, titleColor, descriptionColor, closeIconColor } =
    getStylesByType(type, theme);
  const { isMobile } = useIsMobile();

  const icons: Record<Type, ReactElement> = {
    info: <IconInfo fill="#454A3F" />,
    error: <IconAlertTriangle />,
  };

  return (
    <Box
      bgcolor={bgColor}
      borderRadius={borderRadius}
      p={16}
      border={`1px solid ${borderColor}`}
      display="flex"
      flexDirection="column"
    >
      <Box
        display="grid"
        gridTemplateColumns="1fr auto"
        gap={6}
        alignItems="start"
      >
        <div
          style={{
            display: "flex",
            marginBottom: isMobile ? "0px" : "7px",
          }}
        >
          {withInfoIcon && (
            <div style={{ marginRight: "8px", display: "flex" }}>
              {icons[type]}
            </div>
          )}

          <div>
            <Typography
              variant="text_5"
              fontWeight={isMobile ? 700 : 500}
              color={titleColor}
              textAlign="start"
              fontSize={isMobile ? 14 : 16}
            >
              {title}
            </Typography>
            {description && (
              <Box width="100%" display="flex" mt={2}>
                <Typography
                  variant="text_3"
                  color={descriptionColor}
                  textAlign="start"
                >
                  {description}
                </Typography>
              </Box>
            )}
          </div>
        </div>

        {onClose && (
          <Box
            width={24}
            height={24}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconClose
              style={{ cursor: "pointer" }}
              onClick={onClose}
              color={closeIconColor}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
