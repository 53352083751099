import * as React from "react";

export const IconHistoryTab = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = "25", height = "24", color = "#D3D3D3" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0996 22C6.57961 22 2.09961 17.53 2.09961 12C2.09961 6.48 6.57961 2 12.0996 2C17.6296 2 22.0996 6.48 22.0996 12C22.0996 17.53 17.6296 22 12.0996 22ZM15.2896 15.71C15.4096 15.78 15.5396 15.82 15.6796 15.82C15.9296 15.82 16.1796 15.69 16.3196 15.45C16.5296 15.1 16.4196 14.64 16.0596 14.42L12.4996 12.3V7.68C12.4996 7.26 12.1596 6.93 11.7496 6.93C11.3396 6.93 10.9996 7.26 10.9996 7.68V12.73C10.9996 12.99 11.1396 13.23 11.3696 13.37L15.2896 15.71Z"
        fill={color}
      />
    </svg>
  );
};
