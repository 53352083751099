export const isImage = (mime: string | undefined) => {
  switch (mime) {
    case "image/jpg":
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/webp":
    case "image/svg+xml":
      return true;
    default:
      return false;
  }
};
