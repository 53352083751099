import { IconVirtualCard } from "atoms/IconVirtualCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IconSuccessVirtual } from "atoms/IconSuccessVirtual";
import { IconEnergyVirtual } from "atoms/IconEnergyVirtual";
import { ReactComponent as IconMirPay } from "atoms/IconMirPay/index.svg";
import { Button } from "components/Button";
import { IconSuccess } from "atoms/IconSuccess";
import { IconError } from "atoms/IconError";
import { useEffect } from "react";
import {
  createVirtualCard,
  setIsOpenVirtualCard,
  setStep,
} from "store/slices/virtualCardSlice";
import { setIsCardInfoOpen } from "store/slices/cardsSlice";
import styles from "./index.module.css";

interface StepTemplateProps {
  icon: JSX.Element;
  title: string;
  description: string;
}

const initialStepItems = [
  {
    title: "Одна карта для всего",
    description: "Возможен выпуск только одной виртуальной дебетовой карты",
    image: <IconSuccessVirtual />,
  },
  {
    title: "Карта доступна сразу",
    description:
      "Можете пользоваться, как только карта появится в личном кабинете",
    image: <IconEnergyVirtual />,
  },
  {
    title: "Mir Pay",
    description:
      "Для снятия наличных в банкоматах рекомендуем привязать карту к Mir Pay",
    image: <IconMirPay />,
  },
];

const StepTemplate = ({ icon, title, description }: StepTemplateProps) => {
  const dispatch = useDispatch();
  const { allCards } = useSelector((state: RootState) => state.cards);

  const handleClick = () => {
    dispatch(setIsOpenVirtualCard(false));
    dispatch(setStep("initial"));

    const newVirtualCardIndex = allCards.findIndex(
      (card) => card.cardType === "VIRTUAL" && card.status === "ACTIVE"
    );

    if (newVirtualCardIndex !== -1) {
      dispatch(setIsCardInfoOpen(newVirtualCardIndex));
    }
  };

  return (
    <div className={styles.containerSuccess}>
      {icon}
      <p className={styles.titleSuccess}>{title}</p>
      <p className={styles.descriptionSuccess}>{description}</p>
      <Button
        variant="primary"
        title="Понятно"
        onClick={handleClick}
        className={styles.btnSuccess}
      />
    </div>
  );
};

const InitialStep = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.virtualCard);

  const handleClick = () => {
    dispatch(createVirtualCard());
  };

  return (
    <div className={styles.containerInitial}>
      <IconVirtualCard />
      <div className={styles.contentInitial}>
        <p className={styles.title}>Виртуальная карта</p>
        <div className={styles.items}>
          {initialStepItems.map(({ title, description, image }) => (
            <div key={title} className={styles.item}>
              <div>{image}</div>
              <div>
                <div className={styles.itemTitle}>{title}</div>
                <div className={styles.itemDescription}>{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Button
        variant="primary"
        title="Выпустить"
        onClick={handleClick}
        isLoading={isLoading}
        className={styles.btn}
      />
    </div>
  );
};

const SuccessStep = () => (
  <StepTemplate
    icon={<IconSuccess />}
    title="Карта оформлена"
    description="Вы можете установить ПИН в свойствах карты через приложение"
  />
);

const ErrorStep = () => (
  <StepTemplate
    icon={<IconError />}
    title="Ошибка оформления карты"
    description="Повторите оформление карты позже"
  />
);

export const VirtualCard = () => {
  const dispatch = useDispatch();
  const { step } = useSelector((state: RootState) => state.virtualCard);

  useEffect(
    () => () => {
      dispatch(setStep("initial"));
    },
    [dispatch]
  );

  return (
    <>
      {step === "initial" && <InitialStep />}
      {step === "success" && <SuccessStep />}
      {step === "error" && <ErrorStep />}
    </>
  );
};
