import * as React from "react";

export const IconTransfersTab = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = "25", height = "24", color = "#D3D3D3" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9799 22H8.8099C5.2799 22 2.8999 19.729 2.8999 16.34V7.67C2.8999 4.28 5.2799 2 8.8099 2H16.9799C20.5199 2 22.8999 4.28 22.8999 7.67V16.34C22.8999 19.729 20.5199 22 16.9799 22ZM15.1699 11.25H8.8199C8.3999 11.25 8.0699 11.59 8.0699 12C8.0699 12.42 8.3999 12.75 8.8199 12.75H15.1699L12.6899 15.22C12.5499 15.36 12.4699 15.56 12.4699 15.75C12.4699 15.939 12.5499 16.13 12.6899 16.28C12.9799 16.57 13.4599 16.57 13.7499 16.28L17.5199 12.53C17.7999 12.25 17.7999 11.75 17.5199 11.47L13.7499 7.72C13.4599 7.43 12.9799 7.43 12.6899 7.72C12.3999 8.02 12.3999 8.49 12.6899 8.79L15.1699 11.25Z"
        fill={color}
      />
    </svg>
  );
};
