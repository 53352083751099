import React from "react";

interface IconCreditCardProps extends React.SVGProps<SVGSVGElement> {
  bgColor?: string;
  size?: number;
  color?: string;
  width?: string;
  height?: string;
}

export const IconCreditCard = (props: IconCreditCardProps) => {
  const { color = "#739B67", size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icons/bold/18/Credit-card">
        <path
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 5H18C20.2091 5 22 6.79086 22 9V15C22 17.2091 20.2091 19 18 19H6C3.79086 19 2 17.2091 2 15V9C2 6.79086 3.79086 5 6 5ZM5.26 14.75H18.74C19.1542 14.75 19.49 14.4142 19.49 14C19.49 13.5858 19.1542 13.25 18.74 13.25H5.26C4.84579 13.25 4.51 13.5858 4.51 14C4.51 14.4142 4.84579 14.75 5.26 14.75Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconCreditBlocked = (props: IconCreditCardProps) => {
  const { color = "#E39191", width = 18, height = 20 } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Lock">
        <path
          id="Lock_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5227 5.39601V6.92935C16.2451 7.46696 17.5 9.02614 17.5 10.8884V15.8253C17.5 18.1308 15.5886 20 13.2322 20H4.7688C2.41136 20 0.5 18.1308 0.5 15.8253V10.8884C0.5 9.02614 1.75595 7.46696 3.47729 6.92935V5.39601C3.48745 2.41479 5.95667 0 8.98476 0C12.0535 0 14.5227 2.41479 14.5227 5.39601ZM9.00508 1.73904C11.0678 1.73904 12.7445 3.37871 12.7445 5.39601V6.7137H5.25553V5.37613C5.26569 3.36878 6.94232 1.73904 9.00508 1.73904ZM9.88911 14.4554C9.88911 14.9424 9.49282 15.3299 8.99491 15.3299C8.50716 15.3299 8.11087 14.9424 8.11087 14.4554V12.2493C8.11087 11.7723 8.50716 11.3848 8.99491 11.3848C9.49282 11.3848 9.88911 11.7723 9.88911 12.2493V14.4554Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconCreditCardClosed = (props: IconCreditCardProps) => {
  const { color = "#454A3F", size = 24 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons/bold/24/close">
        <path
          id="Vector"
          d="M12 2.25C6.6239 2.25 2.25 6.6239 2.25 12C2.25 17.3761 6.6239 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.6239 17.3761 2.25 12 2.25ZM16.0608 15L15 16.0608L12 13.0608L9 16.0608L7.93922 15L10.9392 12L7.93922 9L9 7.93922L12 10.9392L15 7.93922L16.0608 9L13.0608 12L16.0608 15Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconCreditCardFrozen = (props: IconCreditCardProps) => {
  const { color = "#9DB6DB", size = 24 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="2" width="20" height="20" rx="5" fill={color} />
      <path
        d="M12 6V18"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4876 7.28418C13.0386 7.56541 12.5242 7.71362 11.9999 7.71275C11.4757 7.71362 10.9612 7.56541 10.5122 7.28418"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5122 16.7137C10.9615 16.4333 11.4758 16.2852 11.9999 16.2852C12.524 16.2852 13.0383 16.4333 13.4876 16.7137"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9.00098L7 15.001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.672 10.982C16.2135 10.7178 15.8329 10.3289 15.5709 9.85709C15.3088 9.38523 15.1753 8.84818 15.1845 8.30347"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32788 13.0173C7.78633 13.2816 8.16696 13.6704 8.42899 14.1423C8.69102 14.6141 8.82457 15.1512 8.81533 15.6959"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.00098L17 15.001"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32788 10.982C7.78633 10.7178 8.16696 10.3289 8.42899 9.85709C8.69102 9.38523 8.82457 8.84818 8.81533 8.30347"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.672 13.0173C16.2135 13.2816 15.8329 13.6704 15.5709 14.1423C15.3088 14.6141 15.1753 15.1512 15.1845 15.6959"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconVirtualCard = (props: IconCreditCardProps) => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5H18C20.2091 5 22 6.79086 22 9V15C22 17.2091 20.2091 19 18 19H6C3.79086 19 2 17.2091 2 15V9C2 6.79086 3.79086 5 6 5ZM5.26 14.75H18.74C19.1542 14.75 19.49 14.4142 19.49 14C19.49 13.5858 19.1542 13.25 18.74 13.25H5.26C4.84579 13.25 4.51 13.5858 4.51 14C4.51 14.4142 4.84579 14.75 5.26 14.75Z"
      fill="#A8CFBD"
    />
    <g clipPath="url(#clip0_10518_15869)">
      <path
        d="M22.4167 22.8333H14.2917C13.2802 22.8333 12.4096 22.5655 11.7733 22.0592C11.0492 21.4827 10.6667 20.6267 10.6667 19.5833C10.6667 17.7842 11.9792 16.7527 13.403 16.4349C13.4967 16.4135 13.5822 16.3657 13.6493 16.297C13.7165 16.2283 13.7625 16.1418 13.7817 16.0477C14.0217 14.9014 14.5458 13.918 15.3186 13.1727C16.2181 12.3079 17.419 11.8275 18.6667 11.8333C19.7761 11.8333 20.7992 12.1986 21.6255 12.8896C22.1903 13.3659 22.6396 13.9643 22.9396 14.6396C22.9718 14.712 23.0209 14.7758 23.0827 14.8256C23.1444 14.8753 23.2172 14.9097 23.2949 14.9258C24.1386 15.1011 24.8908 15.4674 25.4567 15.9836C26.2483 16.7046 26.6667 17.6902 26.6667 18.8333C26.6667 19.9583 26.2174 20.9858 25.4014 21.7267C24.6155 22.4405 23.5555 22.8333 22.4167 22.8333Z"
        fill="#E8F1FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_10518_15869">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(10.6667 9.33325)"
        />
      </clipPath>
    </defs>
  </svg>
);
