import { ReactElement, useState } from "react";

import RemoveIcon from "./assets/remove-icon.png";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  ActionContainer,
  ActionTitle,
  ItemCardActionsBlock,
  ItemCardContainer,
  ItemCardIconWrapper,
  ItemCardNumber,
  ItemCardRemoveIcon,
  ItemCardTitle,
} from "./components";

import RadioButton from "components/RadioButton/RadioButton";
import { IconDots } from "atoms/IconDots/IconDots";
import { Box } from "@mui/system";

interface ItemCardProps {
  title?: string;
  cardNumber?: string;
  checked: boolean;
  onChange: () => void;
  onDelete?: () => void;
  background?: string;
  icon: ReactElement;
  disabledMenu?: boolean;
}

export const ItemCard = ({
  title,
  checked,
  cardNumber,
  onChange,
  onDelete,
  background,
  icon,
  disabledMenu,
}: ItemCardProps) => {
  const [showActions, setShowActions] = useState(false);

  const actions = [
    {
      icon: <ItemCardRemoveIcon src={RemoveIcon} />,
      title: "Удалить карту",
      color: "var(--error-color-primary)",
      action: () => {
        if (onDelete) onDelete();
        setShowActions(false);
      },
    },
  ];

  const handleClickOutside = () => {
    setShowActions(false);
  };

  const rootElement = useOutsideClick(handleClickOutside, { useCapture: true });

  return (
    <ItemCardContainer
      style={{
        background: showActions
          ? "var(--main-color-bg-widgets)"
          : "transparent",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          onClick={() => setShowActions((prevState) => !prevState)}
          sx={{
            pointerEvents: disabledMenu ? "none" : "auto",
            display: "flex",
            cursor: "pointer",
          }}
        >
          <IconDots />
        </Box>

        <ItemCardIconWrapper background={background || ""}>
          {icon}
        </ItemCardIconWrapper>

        <ItemCardTitle>
          {title}
          {cardNumber && <ItemCardNumber> • {cardNumber}</ItemCardNumber>}
        </ItemCardTitle>
      </Box>

      <RadioButton changed={onChange} isSelected={checked} />

      {showActions && (
        <ItemCardActionsBlock>
          {actions.map((action) => {
            return (
              <ActionContainer
                onClick={action.action}
                key={action.title}
                ref={rootElement}
              >
                {action.icon}
                <ActionTitle style={{ color: action.color }}>
                  {action.title}
                </ActionTitle>
              </ActionContainer>
            );
          })}
        </ItemCardActionsBlock>
      )}
    </ItemCardContainer>
  );
};
