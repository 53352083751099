import { cardsSelector } from "store";
import { useDispatch, useSelector } from "react-redux";
import { CardActions } from "components/CardsInfo/CardActions";
import { CardInfo } from "pages/cards/modal/cardsSlider/CardInfo";
import { useEffect } from "react";
import {
  CARD_ACTION,
  cardActionsToggle,
  setScreen,
} from "store/slices/cardsSlice";

export const CardModalWrapper = ({
  handleMenuClick,
}: {
  handleMenuClick: () => void;
}) => {
  const dispatch = useDispatch();
  const { isCardActionsOpen, screen } = useSelector(cardsSelector);

  useEffect(() => {
    return () => {
      dispatch(cardActionsToggle());
      dispatch(setScreen(CARD_ACTION.INITIAL));
    };
  }, [dispatch]);

  return isCardActionsOpen ? (
    screen === CARD_ACTION.INITIAL ? (
      <CardInfo handleMenuClick={handleMenuClick} />
    ) : (
      <CardActions />
    )
  ) : (
    <CardInfo handleMenuClick={handleMenuClick} />
  );
};
