import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAccountInfoOpen,
  setChosenCardIndex,
  setIsCardInfoOpen,
} from "store/slices/cardsSlice";
import { Card } from "./Card";
import { RootState } from "store";

const CardInfoItemContainer = styled(Box)({
  display: "flex",
  columnGap: "9px",
  alignItems: "center",
  cursor: "pointer",
  width: "fit-content",
});

export const ConnectedCards = ({ cards }: { cards: any }) => {
  const dispatch = useDispatch();
  const { isCardInfoOpen } = useSelector((state: RootState) => state.cards);

  const openCard = (index: any) => {
    dispatch(setIsAccountInfoOpen(index));

    if (isCardInfoOpen) {
      dispatch(setChosenCardIndex(index));
    } else {
      dispatch(setIsCardInfoOpen(index));
    }
  };

  return (
    <Box mt={18}>
      <Typography
        sx={{
          color: "#454A3F",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
        mb={14}
      >
        Привязанные карты
      </Typography>
      <Box>
        <CardInfoItemContainer>
          {cards.map((card, index) => {
            return (
              <div onClick={() => openCard(index)} key={index}>
                <Card card={card} />
              </div>
            );
          })}
        </CardInfoItemContainer>
      </Box>
    </Box>
  );
};
