import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ChatAttachmentDto } from "api/notification";
import { IconDocument } from "atoms/IconDocument/IconDocument";
import { IconImage } from "atoms/IconImage/IconImage";
import theme from "theme";
import { isImage } from "utils/isImage";

type FilesMessagesProps = {
  files: ChatAttachmentDto[];
  onClick: (file: ChatAttachmentDto) => () => void;
  user?: string;
};

const FilesMessages = ({ files, onClick, user }: FilesMessagesProps) => {
  if (!files.length) return null;

  return (
    <Box>
      {files.map((file) => {
        const isFileImage = isImage(file.mimeType);

        return (
          <Box
            sx={{ cursor: "pointer" }}
            onClick={onClick(file)}
            key={file.id}
            display="flex"
            alignItems="center"
            // flexDirection={isFileImage}
            gap={6}
          >
            {isFileImage ? (
              <Box height={117}>
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}/notification/v2/chat/attachment/${file.id}`}
                  loading="lazy"
                  style={{ height: "100%" }}
                />
              </Box>
            ) : (
              <IconDocument />
            )}
            {!isFileImage && (
              <Typography
                typography="text_5"
                color={theme.primary.brand.blue[400]}
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: 150,
                }}
              >
                {file.fileName}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default FilesMessages;
