import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  checkCurrentPassword,
  getBankAddress,
  getBankDocuments,
  getBankRates,
  getUserInfo,
  updateLogin,
  updatePassword,
} from "./asyncThunks";
import { ErrorResponse, PmpDto } from "api/auth";
import { logout } from "../auth";
import { AddressResponse } from "api/account";
import { ProfileModalType } from "pages/profile/models";

type UpdatePasswordSteps =
  | "CURRENT_PASSWORD"
  | "NEW_PASSWORD"
  | "SUCCESS_UPDATE";

interface BankRates {
  currencyCode: string;
  currencySymbol: string;
  currentRate: string;
  flag: string;
  isIncrease: boolean;
}

export interface Error extends ErrorResponse {
  status?: number;
}

export interface FilterState {
  cashIn: boolean;
  allTime: boolean;
  RUB: boolean;
  TJS: boolean;
  KGS: boolean;
  KZT: boolean;
  AMD: boolean;
  search: string;
}
export type FilterKeys = keyof Omit<FilterState, "search">;

export const initFiltersState: FilterState = {
  cashIn: false,
  allTime: false,
  RUB: false,
  TJS: false,
  KGS: false,
  KZT: false,
  AMD: false,
  search: "",
};

export type ProfileState = {
  isProfileLoading: boolean;
  isLoading: boolean;
  isLoginUpdated: boolean;
  isModalOpened: boolean;
  user: PmpDto | null;
  error: Error | null;
  errorCurrentPassword: ErrorResponse | null;
  isBiometryConnected: string;
  isLoadingBankRates: boolean;
  isLoadingBankAddress: boolean;
  isLoadingBankDocuments: boolean;
  bankRates: Array<BankRates>;
  bankAddress: Array<AddressResponse>;
  bankDocuments: Blob | null;
  currentPassword: string;
  updatePasswordStep: UpdatePasswordSteps;
  filters: FilterState;
};

const initialState: ProfileState = {
  isProfileLoading: false,
  isLoading: false,
  isLoginUpdated: false,
  isModalOpened: false,
  user: null,
  error: null,
  errorCurrentPassword: null,
  isBiometryConnected: "",
  isLoadingBankRates: false,
  isLoadingBankAddress: false,
  isLoadingBankDocuments: false,
  bankRates: [],
  bankAddress: [],
  bankDocuments: null,
  currentPassword: "",
  updatePasswordStep: "CURRENT_PASSWORD",
  filters: initFiltersState,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setBiometryConnected: (state, { payload }) => {
      state.isBiometryConnected = payload;
    },
    setIsLoginUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoginUpdated = payload;
    },
    setIsModalOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalOpened = payload;
    },
    setIsllCardsShow: (state) => {
      if (state.user) {
        state.user.showAllCards = !state.user?.showAllCards;
      }
    },
    setCurrentPassword: (state, { payload }: PayloadAction<string>) => {
      state.currentPassword = payload;
    },
    setUpdatePasswordStep: (
      state,
      { payload }: PayloadAction<UpdatePasswordSteps>
    ) => {
      state.updatePasswordStep = payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearErrorCurrentPassword: (state) => {
      state.errorCurrentPassword = null;
    },
    setFilters: (state, { payload }: PayloadAction<Partial<FilterState>>) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    toggleFilter: (state, { payload }: PayloadAction<FilterKeys>) => {
      state.filters[payload] = !state.filters[payload];
    },
  },
  extraReducers: {
    // Profile
    [getUserInfo.pending.type]: (state) => {
      state.isProfileLoading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [getUserInfo.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PmpDto>
    ) => {
      state.isProfileLoading = false;
      state.user = payload;
    },
    [getUserInfo.rejected.type]: (state, { payload }) => {
      state.isProfileLoading = false;
      state.error = payload?.response?.data || undefined;
    },
    // Обновление логина
    [updateLogin.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [updateLogin.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PmpDto>
    ) => {
      state.isLoading = false;
      state.isLoginUpdated = true;
      state.user = payload;
    },
    [updateLogin.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error =
        { ...payload?.response?.data, status: payload?.response?.status } ||
        undefined;
    },
    // Обновление пароля
    [checkCurrentPassword.pending.type]: (state) => {
      state.isLoading = true;
    },
    [checkCurrentPassword.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.updatePasswordStep = "NEW_PASSWORD";
    },
    [checkCurrentPassword.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.errorCurrentPassword =
        { ...payload?.response?.data, status: payload?.response?.status } ||
        undefined;
    },
    [updatePassword.pending.type]: (state) => {
      if (state.error) {
        state.error = null;
      }
      state.isLoading = true;
    },
    [updatePassword.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PmpDto>
    ) => {
      state.isLoading = false;
      state.updatePasswordStep = "SUCCESS_UPDATE";
      state.user = payload;
    },
    [updatePassword.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error =
        { ...payload?.response?.data, status: payload?.response?.status } ||
        undefined;
    },

    [getBankRates.pending.type]: (state) => {
      state.isLoadingBankRates = true;
    },
    [getBankRates.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Array<BankRates>>
    ) => {
      state.isLoadingBankRates = false;
      state.bankRates = payload;
    },
    [getBankRates.rejected.type]: (state) => {
      state.isLoadingBankRates = false;
    },

    [getBankAddress.pending.type]: (state) => {
      state.isLoadingBankAddress = true;
    },
    [getBankAddress.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Array<AddressResponse>>
    ) => {
      state.bankAddress = payload;
      state.isLoadingBankAddress = false;
    },
    [getBankAddress.rejected.type]: (state) => {
      state.isLoadingBankAddress = false;
    },

    [getBankDocuments.pending.type]: (state) => {
      state.isLoadingBankDocuments = true;
    },
    [getBankDocuments.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Blob>
    ) => {
      state.bankDocuments = payload;
      state.isLoadingBankDocuments = false;
    },
    [getBankDocuments.rejected.type]: (state) => {
      state.isLoadingBankDocuments = false;
    },

    [logout.fulfilled.type]: () => initialState,
  },
});

export default profileSlice;
