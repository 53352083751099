import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  checkNotifications,
  getNewsList,
  getNotificationList,
} from "./asyncThunks";
import {
  CheckNewNotificationsResponse,
  NotificationDto,
} from "api/notification";
import { groupByDate } from "./utils";
import { Notification } from "organisms/Notifications/Notifications";

export enum PUSH_TYPES {
  MESSAGE = "MESSAGE",
  NEWS = "NEWS",
}

export interface NotificationState {
  isLoading: boolean;
  newNotifications: boolean;
  newNews: boolean;
  notifications: Record<string, NotificationDto[]>;
  news: Record<string, NotificationDto[]>;
  selected: NotificationDto;
  push_type: PUSH_TYPES | null;
}

const initialState: NotificationState = {
  isLoading: false,
  notifications: {},
  news: {},
  selected: {},
  newNotifications: false,
  newNews: false,
  push_type: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    setSelected: (
      state,
      {
        payload: { notification, date },
      }: PayloadAction<{ notification: NotificationDto; date: string }>
    ) => {
      const { id } = notification;
      state.selected = notification;

      state.news[date] = state.news[date].map((n) =>
        n.id === id ? { ...n, readStatus: true } : n
      );

      const hasUnread = Object.values(state.news)
        .flat()
        .some((el) => !el.readStatus);

      state.newNews = hasUnread;
    },
    clearSelected: (state) => {
      state.selected = {};
    },
    setReadNews: (state) => {
      state.newNews = false;
    },
    clearNotifications: (state) => {
      const filterByField = (
        obj: Record<string, NotificationDto[]>,
        fieldName: string
      ): Record<string, NotificationDto[]> =>
        Object.fromEntries(
          Object.entries(obj).filter(([_, array]) =>
            array.some(
              (item) => item[fieldName as keyof NotificationDto] !== undefined
            )
          )
        );

      state.notifications = filterByField(state.notifications, "pushFromFb");
      state.news = {};
    },
    setPushType: (state, { payload }) => {
      state.push_type = payload;
    },
    setNotification: (state, { payload }: PayloadAction<Notification[]>) => {
      state.notifications = groupByDate(
        payload,
        state.notifications,
        "d MMMM, yyyy"
      );
    },
    resetStore: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNotificationList.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.notifications = groupByDate(
          payload.notifications,
          state.notifications,
          "d MMMM, yyyy"
        );
      }
    );
    builder.addCase(getNotificationList.rejected, (state) => {
      state.isLoading = false;
      state.notifications = {};
    });
    builder.addCase(
      getNewsList.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.news = groupByDate(
          payload.notifications,
          state.news,
          "d MMMM, yyyy"
          // "LLLL yyyy"
        );
      }
    );
    builder.addCase(getNewsList.rejected, (state) => {
      state.isLoading = false;
      state.news = {};
    });
    builder.addCase(
      checkNotifications.fulfilled,
      (state, { payload }: PayloadAction<CheckNewNotificationsResponse>) => {
        state.newNotifications = !!payload.newNotifications;
        state.newNews = !!payload.newNews;
      }
    );
  },
});
