import React from "react";

export const IconCircleClose = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = "20", height = "20", color = "#739B67" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 1.87494C5.51992 1.87494 1.875 5.51986 1.875 9.99994C1.875 14.48 5.51992 18.1249 10 18.1249C14.4801 18.1249 18.125 14.48 18.125 9.99994C18.125 5.51986 14.4801 1.87494 10 1.87494ZM13.384 12.4999L12.5 13.3839L10 10.8839L7.5 13.3839L6.61601 12.4999L9.11601 9.99994L6.61601 7.49994L7.5 6.61595L10 9.11595L12.5 6.61595L13.384 7.49994L10.884 9.99994L13.384 12.4999Z"
        fill={color}
      />
    </svg>
  );
};
