import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { formatAmount } from "utils/formatCurrency";
import { setIsAccountInfoOpen } from "store/slices/cardsSlice";
import { IconWalletGray } from "atoms/IconWalletGray";
import useSizeKey from "hooks/useSize";

const AccountsListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
});

const AccountsContainer = styled(Box)({
  display: "flex",
  columnGap: "12px",
  cursor: "pointer",
});

export const allowSizes = ["l", "xl"];

const AccountsList = () => {
  const dispatch = useDispatch();
  const size = useSizeKey();

  const {
    cards: {
      account: { accountNumber, availableBalance, currency, description },
    },
  } = useSelector((state: any) => state);

  const accountAmount = formatAmount(availableBalance, currency?.sign);

  return (
    <AccountsListContainer>
      <Box
        sx={{
          position: !allowSizes.includes(size) ? "absolute" : "static",
          transform: !allowSizes.includes(size)
            ? "translateY(calc(-100% - 14px))"
            : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: "12px",
            alignItems: "center",
          }}
        >
          <Box sx={{ padding: "4px 0", display: "flex", alignItems: "center" }}>
            <IconWalletGray />
          </Box>
          <Typography
            variant="text_10"
            sx={{
              color: "var(--main-color-text-title)",
              fontWeight: 400,
              fontSize: "16px",
            }}
          >
            Мой баланс
          </Typography>
        </Box>

        <Box>
          <AccountsContainer
            onClick={() => dispatch(setIsAccountInfoOpen(accountNumber))}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                noWrap
                sx={{
                  color: "var(--main-color-text-title)",
                  fontWeight: 500,
                  fontSize: "44px",
                  marginBottom: "24px",

                  "@media (max-width:588px)": {
                    fontSize: "38px",
                  },
                }}
              >
                {accountAmount}
              </Typography>
            </Box>
          </AccountsContainer>
        </Box>
      </Box>
    </AccountsListContainer>
  );
};

export default AccountsList;
