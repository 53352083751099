import { StatementHistoryElement } from "api/account";

export interface HistoryChartProps {
  data: StatementHistoryElement[];
  containerRef: React.RefObject<HTMLDivElement>;
  expenseSum: number;
  incomeSum: number;
  onSelect: (date: string | null) => void;
  selectedDate?: string;
  currencySign?: string;
  isMobile?: boolean;
  isLoading?: boolean;
  barWidth?: number;
  barHeight?: number;
  defaultWidth?: number;
  chartType?: "income" | "expense";
}

export enum ChartGroupKey {
  RemainValue = "remainValue",
  ExpenseValue = "expenseValue",
  IncomeValue = "incomeValue",
}

export enum GroupColor {
  White = "#fff",
  Orange = "#DEB16E",
  Green = "#739B67",
}

export enum GroupOpacityColor {
  White = "#F9F9F9",
  Orange = "#ECDABF",
  Green = "#C1D1BC",
}

export type DataElement = d3.SeriesPoint<{
  [key: string]: number;
}>;

export type SeriesElement = d3.Series<
  {
    [key: string]: number;
  },
  string
>;

export type D3Event<T extends Event, E extends Element> = T & {
  currentTarget: E;
};
