import { Box, Stack } from "@mui/material";
import { IconRefresh } from "atoms/IconRefresh/IconRefresh";
import { Button } from "components";

import { ReactComponent as TriangleErrorIcon } from "./assets/triange-error.svg";
import theme from "theme";
import React from "react";
import { ErrorBlockProps } from "./ErrorBlock.types";

export const ErrorBlock: React.FC<ErrorBlockProps> = ({ onRefresh }) => (
  <Stack spacing={8} width="100%" alignItems="center" sx={{ margin: "12px 0" }}>
    <Box
      sx={{
        width: 128,
        height: 128,
        borderRadius: "50%",
        backgroundColor: "#FFF",
        position: "relative",
      }}
    >
      <TriangleErrorIcon
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    </Box>

    <Button
      onClick={onRefresh}
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        gap: 4,
      }}
      variant="text"
      title="Обновить"
      startIcon={
        <IconRefresh color={theme.primary.brand.blue["400"]} size={17} />
      }
    />
  </Stack>
);
