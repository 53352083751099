import env from "../env";

const BASE_URL = `${env.REACT_APP_BASE_PATH}`;

export async function downloadPDF(path, fileName) {
  const url = `${BASE_URL}account/v1/info/documents?type=${path}`;
  try {
    const response = await fetch(url, { method: "GET" });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const blob = await response.blob();
    const pdfBlob = new Blob([blob], { type: "application/pdf" });
    const objectURL = window.URL.createObjectURL(pdfBlob);

    const tempLink = document.createElement("a");
    tempLink.href = objectURL;
    tempLink.setAttribute("download", fileName);

    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(objectURL);
  } catch (error) {
    throw new Error("Error downloading PDF");
  }
}
