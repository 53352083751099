export const IconEnergyVirtual = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="11" fill="white" />
    <path
      d="M19.6704 12.9226L15.1287 20.8809C14.9566 21.1753 14.9571 21.5397 15.13 21.8336C15.3029 22.1275 15.6211 22.3049 15.962 22.2976H18.3454C18.6518 22.2954 18.9465 22.4155 19.1639 22.6314C19.3814 22.8473 19.5037 23.1411 19.5037 23.4476V26.4559C19.5065 26.8214 19.7472 27.1423 20.0972 27.2475C20.4472 27.3527 20.8249 27.2176 21.0287 26.9142L25.4287 20.3142C25.6213 20.0239 25.6394 19.6513 25.476 19.3436C25.3126 19.0359 24.9938 18.8423 24.6454 18.8392H22.3787C21.7436 18.8392 21.2287 18.3244 21.2287 17.6892V13.3392C21.2314 12.9597 20.9773 12.6264 20.6107 12.5284C20.244 12.4303 19.8575 12.5924 19.6704 12.9226Z"
      fill="#739B67"
    />
  </svg>
);
