import * as React from "react";

export const IconMainTab = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = "25", height = "24", color = "#D3D3D3" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5963 19.896V17.1441C9.5963 16.4416 10.17 15.8721 10.8776 15.8721H13.4643C13.8042 15.8721 14.1301 16.0061 14.3704 16.2446C14.6106 16.4832 14.7456 16.8067 14.7456 17.1441V19.896C14.7435 20.1881 14.8589 20.4689 15.0661 20.6762C15.2734 20.8834 15.5554 21 15.8496 21H17.6144C18.4387 21.0021 19.2299 20.6786 19.8135 20.1007C20.397 19.5229 20.725 18.7383 20.725 17.92V10.0802C20.725 9.41921 20.4299 8.79226 19.9192 8.3682L13.9156 3.60828C12.8713 2.77371 11.375 2.80065 10.3619 3.67228L4.49531 8.3682C3.96047 8.77976 3.6408 9.40857 3.625 10.0802V17.912C3.625 19.6175 5.01764 21 6.73556 21H8.46006C9.0711 21 9.5677 20.5106 9.57213 19.904L9.5963 19.896Z"
        fill={color}
      />
    </svg>
  );
};
