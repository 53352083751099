import { Tab, Tabs } from "@mui/material";
import { HistoryChartTabsKeys } from "constants/chart";
import React from "react";

import { ReactComponent as BarChartIcon } from "./assets/bar-chart.svg";
import { ReactComponent as PieChartICon } from "./assets/pie-chart.svg";
import { HistoryChartTabsProps } from "./HistoryChartTabs.types";
import theme from "theme";

export const HistoryChartTabs: React.FC<HistoryChartTabsProps> = ({
  isMobile,
  ...restProps
}) => {
  const getLabel = (id: string, label: string) => {
    if (isMobile) return "";

    return id === restProps.value ? label : "";
  };

  return (
    <Tabs
      centered
      {...restProps}
      sx={{
        "& .Mui-selected": {
          backgroundColor: "#fff",
          color: "#739B67 !important",

          "& svg": {
            path: {
              fill: "#739B67",
            },
          },
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },

        "&": {
          overflow: "unset",
          minHeight: "unset",
        },
        "& .MuiTab-root": {
          borderRadius: 10,
          padding: 10,
          height: 36,
          minHeight: "unset",
          minWidth: "unset",
          fontWeight: 500,
          fontSize: 16,
          gap: 5,

          [theme.breakpoints.down("md")]: {
            padding: 5,
            borderRadius: 5,
            height: 28,
          },
        },
      }}
    >
      <Tab
        label={getLabel(HistoryChartTabsKeys.Days, "дни")}
        value={HistoryChartTabsKeys.Days}
        icon={<BarChartIcon />}
        iconPosition="start"
      />
      <Tab
        label={getLabel(HistoryChartTabsKeys.Categories, "категории")}
        value={HistoryChartTabsKeys.Categories}
        icon={<PieChartICon />}
        iconPosition="start"
      />
    </Tabs>
  );
};
