import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { ReactNode } from "react";
import { useOutsideClick } from "hooks/useOutsideClick";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const ContactsBottomSheet: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
}) => {
  const refEl = useOutsideClick(onClose, { useCapture: true });

  if (!isOpen) return null;

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          boxSizing: "border-box",
          outline: "none",
        }}
      >
        <Box
          ref={refEl}
          sx={{
            backgroundColor: "#FFF",
            backdropFilter: "blur(12.9px)",
            position: "relative",
            borderRadius: "16px 16px 0 0",
            padding: "16px 0 32px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              top: "12px",
              width: "32px",
              height: "4px",
              borderRadius: "100px",
              opacity: 0.4,
              background: "#79747E",
              transform: "translateX(-50%)",
            }}
          />
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
