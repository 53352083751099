export const IconVirtualCard = (props) => (
  <svg
    width="100%"
    height="278"
    viewBox="0 0 462 278"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_9481_11836)">
      <g clipPath="url(#clip0_9481_11836)">
        <rect x="25" y="14" width="412" height="228" rx="28" fill="white" />
        <rect
          x="25"
          y="14"
          width="412"
          height="228"
          rx="28"
          fill="url(#paint0_linear_9481_11836)"
        />
        <path
          d="M206.875 205H72.8125C56.1217 205 41.7564 200.569 31.2583 192.193C19.3112 182.653 13 168.492 13 151.228C13 121.461 34.6562 104.394 58.1481 99.1354C59.6934 98.7824 61.1045 97.9904 62.2128 96.8544C63.321 95.7183 64.0795 94.2862 64.3975 92.7293C68.3575 73.7641 77.0045 57.4928 89.7559 45.1613C104.598 30.854 124.413 22.9054 145 23.0008C163.305 23.0008 180.186 29.0451 193.819 40.4769C203.138 48.3589 210.552 58.2591 215.501 69.4313C216.034 70.6305 216.843 71.6855 217.863 72.509C218.882 73.3326 220.082 73.901 221.364 74.1674C235.286 77.068 247.697 83.1278 257.035 91.6693C270.096 103.598 277 119.905 277 138.818C277 157.432 269.585 174.432 256.122 186.692C243.154 198.501 225.664 205 206.875 205Z"
          fill="#E2F2E7"
          fillOpacity="0.57"
        />
        <path
          d="M325 193.004H332.974C333.699 193.004 335.874 192.763 336.84 196.13C337.565 198.294 338.531 201.66 339.981 206.709H340.465C341.914 201.419 343.123 197.813 343.606 196.13C344.572 192.763 346.989 193.004 347.955 193.004H355.446V216.087H347.714V202.381H347.23L343.123 216.087H337.323L333.216 202.381H332.491V216.087H325M358.587 193.004H366.319V206.709H367.044L372.119 195.408C373.085 193.244 375.26 193.004 375.26 193.004H382.509V216.087H374.776V202.381H374.293L369.219 213.682C368.252 215.846 365.836 216.087 365.836 216.087H358.587M392.899 209.114V216.087H385.65V204.064H409.33C408.364 206.95 404.981 209.114 401.115 209.114"
          fill="#7E7F7A"
        />
        <path
          d="M409.815 203.122C410.781 198.794 407.882 193.504 401.599 193.504H385.168C385.651 198.553 390.001 203.122 394.592 203.122"
          fill="url(#paint1_linear_9481_11836)"
        />
        <g filter="url(#filter1_f_9481_11836)">
          <rect
            x="269"
            y="60"
            width="161"
            height="123"
            rx="25"
            fill="#D2E8C2"
          />
        </g>
        <g filter="url(#filter2_f_9481_11836)">
          <rect x="25" y="111" width="109" height="83" rx="25" fill="#94BB9C" />
        </g>
      </g>
      <rect
        x="26"
        y="15"
        width="410"
        height="226"
        rx="27"
        stroke="white"
        strokeWidth="2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_9481_11836"
        x="0.299999"
        y="0.299999"
        width="461.4"
        height="277.4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="12.35" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.650992 0 0 0 0 0.650992 0 0 0 0 0.650992 0 0 0 0.19 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9481_11836"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9481_11836"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_9481_11836"
        x="162.9"
        y="-46.1"
        width="373.2"
        height="335.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="53.05"
          result="effect1_foregroundBlur_9481_11836"
        />
      </filter>
      <filter
        id="filter2_f_9481_11836"
        x="-81.1"
        y="4.9"
        width="321.2"
        height="295.2"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="53.05"
          result="effect1_foregroundBlur_9481_11836"
        />
      </filter>
      <linearGradient
        id="paint0_linear_9481_11836"
        x1="231"
        y1="14"
        x2="231"
        y2="242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9481_11836"
        x1="406.915"
        y1="190.378"
        x2="387.584"
        y2="190.378"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E7F7A" />
        <stop offset="1" stopColor="#7E7F7A" />
      </linearGradient>
      <clipPath id="clip0_9481_11836">
        <rect x="25" y="14" width="412" height="228" rx="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
