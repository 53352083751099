import { Box } from "@mui/material";
import { InputText } from "components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { cardsSelector, profileSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { updateAccount } from "store/slices/cardsSlice";
import { Button } from "components/Button";

type LoginModalProps = {
  name?: string;
  onClose: () => void;
  accId: string;
};

export const AccountModal = ({ name, onClose, accId }: LoginModalProps) => {
  const dispatch = useDispatch();
  const { isLoginUpdated } = useSelector(profileSelector);
  const [newAccountName, setNewAccountName] = useState("");
  const [operationSuccess, setOperationSuccess] = useState(false);
  const { isMobile } = useIsMobile();

  const disabled = newAccountName.length === 0 || newAccountName === name;
  const handleClick = () => {
    dispatch(
      updateAccount({
        acctId: accId,
        description: newAccountName,
      })
    )
      // @ts-ignore
      .unwrap()
      .then(() => {
        setOperationSuccess(true);
      })
      .catch((err: any) => {
        return err;
      });
  };

  if (operationSuccess) {
    return (
      <Box
        height="100%"
        pt={{ xs: 46, md: 32 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          title="Название счёта успешно изменено"
          icon={<IconSuccess />}
          height="100%"
          buttonsMarginTop={52}
          titleStyle={{
            fontSize: "28px",
            fontWeight: 500,
            lineHeight: "36px",
            color: "#393E46",
          }}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              onClick: onClose,
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box px={isMobile ? 20 : 32} height="100%">
      <Box boxSizing="border-box">
        <InputText
          value={newAccountName}
          name="newLogin"
          styles={{ gap: "8px" }}
          labelStyles={{ fontSize: "16px", color: "#454A3F", fontWeight: 400 }}
          label="Название для  счёта"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewAccountName(e.target.value);
          }}
          maxLength={26}
          style={{ color: "#454A3F" }}
        />

        <Box
          width="100%"
          mt={32}
          display="flex"
          flexDirection="column"
          gap={10}
        >
          <Button
            isLoading={isLoginUpdated}
            disabled={disabled}
            variant="primary"
            onClick={handleClick}
            title="Применить"
          />
          <Button variant="secondary" onClick={onClose} title="Отмена" />
        </Box>
      </Box>
    </Box>
  );
};
