export const BlinkingCursor = () => {
  return (
    <span
      style={{
        display: "inline-block",
        width: "1px",
        height: "1em",
        backgroundColor: "black",
        marginLeft: "2px",
        animation: "blink 1s steps(2, start) infinite",
      }}
    />
  );
};
