import styled from "@emotion/styled/macro";

export const Container = styled.div`
  padding: 16px;
  border-radius: 0 0 8px 8px;
  border: none;
  background-color: var(--warning-color-secondary-light);
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const Title = styled.p`
  margin: 0px;
  margin-left: 8px;

  color: var(--warning-color-primary);

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  @media (max-width: 550px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Description = styled.p`
  margin: 0 20px 0 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);

  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

export const CloseButton = styled.button`
  border: none;
  outline: 0;
  background: none;
  padding: 0;
`;
