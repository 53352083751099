import { StatementCategoryFull } from "api/account";
import { HistoryCategoryChartProps } from "./HistoryCategoryChart.types";

import * as d3 from "d3";
import { Box, Stack } from "@mui/material";
import { memo, useLayoutEffect } from "react";
import {
  DISABLED_COLOR,
  INNER_RADIUS_RATIO,
  MIN_VALUE_TO_SHOW_ICON,
  PLACEHOLDER_DATA,
  WHITE_FILTER_COLOR,
} from "./HistoryCategoryChart.const";

import { HistoryCategoryItem } from "./components/HistoryCategoryItem";

export const HistoryCategoryChart: React.FC<HistoryCategoryChartProps> = memo(
  ({ data, onSelectCategory, selectedCategoryId, currencySign = "₽" }) => {
    const isEmpty = !data[0];
    const categories = isEmpty ? PLACEHOLDER_DATA : data;

    const width = 124;
    const height = 124;
    const iconWidth = 12;
    const iconHeight = 12;

    const radius = Math.min(width, height) / 2;

    useLayoutEffect(() => {
      const container = d3.select(".category-chart");

      const arc = d3
        .arc<d3.PieArcDatum<StatementCategoryFull>>()
        .innerRadius(radius / INNER_RADIUS_RATIO)
        .outerRadius(radius)
        .cornerRadius(20);

      const pie = d3
        .pie<StatementCategoryFull>()
        .padAngle(5 / radius)
        .sort((a, b) => Number(b.percent) - Number(a.percent))
        .value((d) =>
          d.percent && d.percent < MIN_VALUE_TO_SHOW_ICON
            ? MIN_VALUE_TO_SHOW_ICON
            : Number(d.percent)
        );

      container.selectAll("g").remove().exit();

      const arcGroups = container.selectAll().data(pie(categories)).join("g");

      arcGroups
        .append("path")
        .attr("d", arc)
        .attr("cursor", isEmpty ? "unset" : "pointer")
        .attr("fill", DISABLED_COLOR)
        .transition()
        .duration(500)
        .attr("fill", (d) => d.data.color!);

      arcGroups.on("click", (_, d) => {
        if (isEmpty) return;

        onSelectCategory(d.data.id!);
      });

      arcGroups
        .append("svg:image")
        .attr("transform", function (d) {
          const cornerRadius = (radius - radius / 1.44) / 2;
          const radiusRounded = radius - cornerRadius;
          const angle = d.startAngle + d.padAngle / 2;

          const x =
            cornerRadius * Math.cos(angle) +
            Math.sqrt(
              radiusRounded * radiusRounded - cornerRadius * cornerRadius
            ) *
              Math.sin(angle) -
            iconWidth / 2;
          const y =
            cornerRadius * Math.sin(angle) -
            Math.sqrt(
              radiusRounded * radiusRounded - cornerRadius * cornerRadius
            ) *
              Math.cos(angle) -
            iconHeight / 2;

          if ((d.data.percent && d.data.percent < 10) || categories.length <= 1)
            return `
          translate(${arc.centroid(d)[0] - iconWidth / 2}, ${
              arc.centroid(d)[1] - iconHeight / 2
            })`;

          return `translate(${x}, ${y})`;
        })
        .attr(
          "xlink:href",
          function (d: d3.PieArcDatum<StatementCategoryFull>) {
            return d.value >= MIN_VALUE_TO_SHOW_ICON ? d.data.logo! : "";
          }
        )
        .attr("cursor", "pointer")
        .attr("filter", WHITE_FILTER_COLOR)
        .attr("width", iconWidth)
        .attr("height", iconHeight);

      container
        .append("g")
        .append("svg:circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", 32.5)
        .attr("fill", "#fff")
        .append("g");
    }, [categories, radius, currencySign, isEmpty, onSelectCategory]);

    useLayoutEffect(() => {
      d3.select(".category-chart")
        .selectAll("path")
        .transition()
        .duration(500)
        .attr("fill", (d) => {
          const itemData = d as d3.PieArcDatum<StatementCategoryFull>;

          return selectedCategoryId && selectedCategoryId !== itemData.data.id
            ? DISABLED_COLOR
            : itemData.data.color!;
        });
    }, [selectedCategoryId, onSelectCategory]);

    return (
      <Box
        marginBottom={{
          xs: 8,
          md: 4,
        }}
      >
        <Stack
          direction={{
            md: "row",
            xs: "column",
          }}
          alignItems={{
            md: "center",
            xs: "flex-start",
          }}
          spacing={{
            xs: 20,
            md: 40,
          }}
        >
          <div
            style={{
              minWidth: width,
              minHeight: height,
            }}
          >
            <svg
              className="category-chart"
              height={height}
              style={{ maxWidth: "100%", height: "auto" }}
              viewBox={`${-width / 2} ${-height / 2} ${width} ${height}`}
              width={width}
            />
          </div>

          {!isEmpty && (
            <Stack
              direction="row"
              gap={{
                xs: 8,
                md: 12,
              }}
              sx={{
                height: "fit-content",
              }}
              flexWrap="wrap"
            >
              {categories.map((el) => (
                <HistoryCategoryItem
                  currencySign={currencySign}
                  key={el.id}
                  category={el}
                  selectedCategoryId={selectedCategoryId}
                  onSelectCategory={() =>
                    onSelectCategory(
                      selectedCategoryId === el.id ? null : el.id!
                    )
                  }
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Box>
    );
  }
);

HistoryCategoryChart.displayName = "HistoryCategoryChart";
