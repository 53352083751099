import styled from "@emotion/styled/macro";

export const Container = styled.div`
  margin-top: 48px;

  @media (max-width: 550px) {
    margin-top: 20px;
  }
`;

export const Title = styled.p`
  margin: 0px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--main-color-text-title);

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`;

export const CardImg = styled.img`
  width: 88px;
  @media (max-width: 1440px) {
    width: 48px;
  }
  @media (max-width: 979px) {
    width: 88px;
  }
  @media (max-width: 700px) {
    width: 48px;
  }
`;

export const TransferWrapper = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const IconWrapper = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 8px;

  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
`;

export const TransferBtnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  column-gap: 24px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 1440px) {
    column-gap: 16px;
  }

  @media (max-width: 980px) {
    column-gap: 16px;
  }

  @media (max-width: 588px) {
    width: fit-content;
  }
`;
export const TransferBtn = styled.div`
  box-sizing: border-box;

  aspect-ratio: 260/180;
  grid-column: span 1;
  border-radius: 18px;
  background: var(--main-color-bg-widgets);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;

  position: relative;

  @media (max-width: 980px) {
    aspect-ratio: 250/180;
  }

  @media (max-width: 588px) {
    height: 120px;
    aspect-ratio: 160/120;
    border-radius: 16px;
  }
`;

export const TransferBtnText = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--main-color-text-title);

  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 588px) {
    font-size: 14px;
  }
`;

export const TransferHistoryBlock = styled.div`
  width: 100%;
`;

export const TransferHistoryBtn = styled.div`
  border-radius: 13px;
  background: var(--main-color-bg-widgets);
  margin-top: 16px;
  padding: 16px 10px;
  border: none;
  color: #739b67;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  &:hover {
    outline: 1px solid var(--brand-color-primary);
  }
`;

export const DateStyled = styled.div`
  background: var(--main-color-white);
  width: calc(100% - 20px);
  padding: 0px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-color-primary);
`;
