import { TimeLocaleDefinition } from "d3";

export enum HistoryChartTabsKeys {
  Days = "days",
  Categories = "categories",
}

export const RU_LOCALE: TimeLocaleDefinition = {
  dateTime: "%a %b %e %X %Y",
  date: "%d/%m/%Y",
  time: "%H%H:%m%m:%s%s",
  periods: ["AM", "PM"],
  days: [
    "воскресенье",
    "понедельник",
    "вторник",
    "среда",
    "четверг",
    "пятница",
    "суббота",
  ],
  shortDays: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
  months: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  shortMonths: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
};
