export const SkyIcon = ({ color = "#B5B5B5", width = 24, height = 18 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.625 17.2501H5.4375C3.92016 17.2501 2.61422 16.8484 1.65984 16.089C0.57375 15.2241 0 13.9402 0 12.3751C0 9.67648 1.96875 8.12914 4.10437 7.65242C4.24485 7.62041 4.37314 7.54861 4.47389 7.44562C4.57464 7.34262 4.64359 7.21279 4.6725 7.07164C5.0325 5.35226 5.81859 3.87711 6.97781 2.75914C8.32705 1.46204 10.1284 0.741427 12 0.750077C13.6641 0.750077 15.1987 1.29805 16.4381 2.33445C17.2853 3.04904 17.9593 3.94658 18.4092 4.95945C18.4576 5.06817 18.5312 5.16382 18.6239 5.23848C18.7165 5.31314 18.8257 5.36467 18.9422 5.38883C20.2078 5.65179 21.3361 6.20117 22.185 6.97554C23.3723 8.05695 24 9.53539 24 11.2501C24 12.9376 23.3259 14.4788 22.102 15.5902C20.9231 16.6609 19.3331 17.2501 17.625 17.2501Z"
      fill={color}
    />
  </svg>
);
