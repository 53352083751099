import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";
import { LoginInput } from "molecules/LoginInput/LoginInput";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { authSelector, useAppThunkDispatch } from "store";
import { authActions, createLogin, registerConfirm } from "store/slices/auth";
import { CheckBlock } from "components/CheckBlock/CheckBlock";
import { showErrorMessage } from "store/slices/alerts";
import { ErrorScreen } from "components/ErrorScreen/ErrorScreen";
import { isServerError } from "utils/isServerError";
import { BackButtonArrow } from "components/BackButtonArrow";
import styles from "./style.module.css";
import { Button } from "components/Button";

export type PasswordStepVariant = "create" | "repeat";

const LoginStep = () => {
  const dispatch = useDispatch();
  const { isLoading, error, login } = useSelector(authSelector);

  const [isDisabledCheck, setIsDisabledCheck] = useState(true);

  const isDisabledChecking = useCallback((value: boolean) => {
    setIsDisabledCheck(!value);
  }, []);

  const handleLogin = () => dispatch(createLogin(login));

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      mt={{ xs: 20, xl: 24 }}
    >
      <LoginInput
        value={login}
        label="Создайте логин для входа"
        placeholder="Введите логин"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (error) dispatch(authActions.resetError());
          dispatch(authActions.setLogin(e.target.value.trim()));
        }}
        // @ts-ignore
        error={error?.title || ""}
      />
      <CheckBlock value={login} isDisabled={isDisabledChecking} type="login" />
      <Button
        isLoading={isLoading}
        disabled={isDisabledCheck || Boolean(error?.title)}
        variant="primary"
        onClick={handleLogin}
        style={{ marginTop: "20px" }}
        title="Продолжить"
      />
    </Box>
  );
};

const PasswordStep = () => {
  const dispatch = useAppThunkDispatch();
  const { isLoading, error, login } = useSelector(authSelector);

  const [step, setStep] = useState<PasswordStepVariant>("create");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isDisabledCheck, setIsDisabledCheck] = useState(true);

  const label =
    step === "create" ? "Создайте пароль для входа" : "Повторите пароль";

  const disabledBtn =
    step === "create"
      ? !isDisabledCheck || Boolean(error?.title)
      : password !== repeatPassword;

  const isDisabledChecking = useCallback((value: boolean) => {
    setIsDisabledCheck(value);
  }, []);

  const handlePassword = () => {
    if (step === "create") {
      setStep("repeat");
    } else {
      dispatch(
        registerConfirm({
          login,
          password,
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.response && err.response.data) {
            dispatch(
              showErrorMessage({
                errorTitle: err.response.data.title,
                errorMessage: err.response.data.subtitle,
              })
            );
          }
        });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={step === "repeat" ? 20 : 0}
    >
      <PasswordInput
        value={step === "create" ? password : repeatPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (error) dispatch(authActions.resetError());
          if (step === "create") {
            setPassword(e.target.value);
          } else {
            setRepeatPassword(e.target.value);
          }
        }}
        label={label}
        placeholder="Введите пароль"
        error={error?.comment || ""}
      />
      <CheckBlock
        value={password}
        isDisabled={isDisabledChecking}
        type="password"
        isPasswordMatch={password === repeatPassword}
        passwordStep={step}
      />
      <Button
        isLoading={isLoading}
        disabled={disabledBtn}
        variant="primary"
        onClick={handlePassword}
        style={{ marginTop: "20px" }}
        title="Продолжить"
      />
    </Box>
  );
};

const FourthScreen = () => {
  const { createAccountStep, error } = useSelector(authSelector);
  const dispatch = useDispatch();

  const generateTitle = useCallback(() => {
    switch (createAccountStep) {
      case "login":
        return "2";
      case "password":
        return "3";
      default:
        return "";
    }
  }, [createAccountStep]);

  const handleBack = () => {
    dispatch(authActions.setLogin(""));
    dispatch(authActions.setRegisterScreen("initial"));
    dispatch(authActions.resetError());
  };

  if (isServerError(error)) {
    return (
      <Box px={{ xs: "30px" }}>
        <ErrorScreen
          title="Произошла ошибка</br>во время регистрации"
          subTitle="Попробуйте снова"
          buttonTitle="Зарегистрироваться"
          buttonStyles={{
            maxWidth: "376px",
          }}
          buttonHandler={handleBack}
        />
      </Box>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.backBtn}>
        <BackButtonArrow onClick={handleBack} />
      </div>
      <div className={styles.titleBlock}>
        <p className={styles.title}>Регистрация</p>
        <p className={styles.title}>
          {generateTitle()}
          <span className={styles.part}>/3</span>
        </p>
      </div>
      <Box component="form" autoComplete="off">
        {createAccountStep === "login" && <LoginStep />}
        {createAccountStep === "password" && <PasswordStep />}
      </Box>
    </div>
  );
};

export default FourthScreen;
