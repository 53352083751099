import React from "react";

interface IconReplyArrowProps extends React.SVGProps<SVGSVGElement> {
  size?: string;
}

export const IconReplyArrow = (props: IconReplyArrowProps) => {
  const { size = "18", color = "#656960" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M15.7499 15.4678C15.6644 15.4678 15.5801 15.4483 15.5033 15.4108C15.4265 15.3734 15.3592 15.3189 15.3066 15.2516C14.5029 14.2225 13.7572 13.4283 12.7289 12.8961C11.7772 12.405 10.5819 12.1487 8.9999 12.1008V14.9053C8.99956 15.0151 8.9671 15.1224 8.90652 15.2139C8.84593 15.3055 8.75988 15.3773 8.65896 15.4206C8.55804 15.4638 8.44667 15.4766 8.33858 15.4573C8.23049 15.438 8.13041 15.3875 8.05068 15.312L1.86318 9.40577C1.8082 9.35325 1.76443 9.29012 1.73453 9.22021C1.70463 9.1503 1.68921 9.07505 1.68921 8.99901C1.68921 8.92297 1.70463 8.84772 1.73453 8.77781C1.76443 8.7079 1.8082 8.64478 1.86318 8.59225L8.05068 2.68601C8.13041 2.61052 8.23049 2.56002 8.33858 2.54073C8.44667 2.52144 8.55804 2.53421 8.65896 2.57745C8.75988 2.6207 8.84593 2.69254 8.90652 2.7841C8.9671 2.87567 8.99956 2.98297 8.9999 3.09276V5.91792C11.6064 6.0378 13.5484 7.0046 14.7778 8.79616C15.7963 10.2801 16.3124 12.3353 16.3124 14.9053C16.3124 15.0544 16.2531 15.1975 16.1476 15.303C16.0422 15.4085 15.8991 15.4678 15.7499 15.4678Z"
        fill={color}
      />
    </svg>
  );
};
