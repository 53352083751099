import { useState, useEffect, useRef, RefObject, useCallback } from "react";

interface UseStickyReturn {
  isSticky: boolean;
  ref: RefObject<HTMLDivElement>;
}

export interface StickyRectConfig {
  /**
   * Отступы после преодоления которых требуется реагировать
   */
  topMargin?: number;
  /**
   * Если `true` то игнорирует позиционирование на странице по оси Y. Равноценно: postion: `sticky`; top: `0`
   */
  ignoreOffsetY?: boolean;
}

const initCong: StickyRectConfig = {
  ignoreOffsetY: false,
  topMargin: 0,
};

const useSticky = ({
  ignoreOffsetY,
  topMargin,
}: StickyRectConfig = initCong): UseStickyReturn => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    (event: Partial<Event>) => {
      if (!ref.current || !event.target) return;
      const marinTop = topMargin || 0;
      const scrollTop = (event.target as Element).scrollTop || 0;
      const rect = ref.current.getBoundingClientRect();
      const pageY = ignoreOffsetY ? scrollTop + rect.y : 0;
      const offset = rect.top + marinTop - pageY;
      setIsSticky(offset <= 0);
    },
    [topMargin, ignoreOffsetY]
  );

  useEffect(() => {
    const scrollableParent: Element | Body =
      ref.current?.closest(".scrollable-container") || document.body;
    scrollableParent.addEventListener("scroll", handleScroll);
    handleScroll({ target: scrollableParent });
    return () => {
      scrollableParent.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, ref };
};

export default useSticky;
