import ru from "date-fns/locale/ru";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { RangePickerProps, RangePickerValues } from "./RangePicker.types";

import styles from "./RangePicker.module.css";

import cn from "classnames";
import moment from "moment";
import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import { Stack, Typography } from "@mui/material";
import { IconChevronLeft } from "atoms/IconChevronLeft/IconChevronLeft";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";
import { useIsMobile } from "hooks/useIsMobile";
import { isFullMonthRange } from "utils/dateUtils";
import { renderDate } from "./RangePicker.utils";
import { getEndOfDay } from "utils/getEndOfDay";

registerLocale("ru", ru);

export const RangePicker: React.FC<RangePickerProps> = ({
  isDisabled,
  className,
  values,
  onChange,
}) => {
  const { isMobile } = useIsMobile();

  const [valuesState, setValuesState] = useState<RangePickerValues>();

  const isFullMonth = isFullMonthRange(valuesState?.from, valuesState?.to);
  const isShowArrows = !isMobile && isFullMonth;
  const isNextArrowDisabled =
    valuesState?.to?.getMonth() === new Date().getMonth() &&
    valuesState?.to?.getFullYear() === new Date().getFullYear();

  const handleChange = (values: [Date | null, Date | null]) => {
    const [from, to] = values;

    const pickerValues = {
      from: moment(from).utc(true).startOf("day").toDate(),
      to: to ? new Date(getEndOfDay(to)) : undefined,
    };

    setValuesState(pickerValues);

    if (from && to) {
      onChange?.(pickerValues as Required<RangePickerValues>);
    }
  };

  const toPrevMonth = () => {
    if (isDisabled) return;

    const from = moment(valuesState?.from).subtract(1, "month").toDate();
    const to = moment(valuesState?.from).subtract(1, "day").toDate();

    handleChange([from, to]);
  };

  const toNextMonth = () => {
    if (isDisabled || isNextArrowDisabled) return;

    const from = moment(valuesState?.from).add(1, "month").toDate();
    const to = moment(valuesState?.from)
      .add(2, "month")
      .subtract(1, "day")
      .toDate();

    const isCurrentMonth =
      to.getMonth() === new Date().getMonth() &&
      to.getFullYear() === new Date().getFullYear();

    handleChange([from, isCurrentMonth ? new Date() : to]);
  };

  // eslint-disable-next-line react/display-name
  const CustomInputDefault = forwardRef(
    (
      {
        value,
        onClick,
        className,
      }: { value?: string; onClick?: () => void; className?: string },
      ref?: ForwardedRef<HTMLDivElement>
    ) => (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        spacing={4}
        className={className}
        ref={ref}
        sx={{
          minWidth: 100,
        }}
      >
        {isShowArrows && (
          <IconChevronLeft
            onClick={toPrevMonth}
            cursor={isDisabled ? "unset" : "pointer"}
            color={isDisabled ? "#8B8C88" : undefined}
          />
        )}

        <IconCalendar
          height={18}
          width={18}
          onClick={onClick}
          cursor="pointer"
        />

        <Typography
          onClick={onClick}
          sx={{
            lineHeight: "20px",
            color: "#739B67",
            fontWeight: 500,
            cursor: "pointer",
          }}
        >
          {renderDate(valuesState || {}, isFullMonth, isMobile)}
        </Typography>

        {isShowArrows && (
          <IconChevronRight
            cursor={isNextArrowDisabled || isDisabled ? "unset" : "pointer"}
            color={isNextArrowDisabled || isDisabled ? "#8B8C88" : undefined}
            onClick={toNextMonth}
          />
        )}
      </Stack>
    )
  );

  useEffect(() => {
    if (!values) return;

    setValuesState(values);
  }, [values]);

  return (
    <div className={cn(styles.wrapper, className)}>
      <ReactDatePicker
        swapRange
        selected={valuesState?.from}
        showPopperArrow={false}
        fixedHeight={false}
        disabled={isDisabled}
        maxDate={moment().toDate()}
        popperPlacement="bottom-end"
        startDate={valuesState?.from}
        endDate={valuesState?.to}
        onChange={handleChange}
        selectsRange
        locale="ru"
        nextMonthButtonLabel=""
        previousMonthButtonLabel=""
        customInput={<CustomInputDefault />}
      />
    </div>
  );
};
