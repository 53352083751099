import { useEffect, useRef } from "react";

export const useOutsideClick = (
  callback: () => void,
  options: {
    useCapture?: boolean;
  } = {}
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { useCapture = false } = options;

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, useCapture);

    return () => {
      document.removeEventListener("click", handleClick, useCapture);
    };
  }, [ref, useCapture, callback]);

  return ref;
};
