import { formatAmount } from "utils/formatCurrency";

export const getExpenseSum = (sum = 0, currency = "₽") => {
  const posSum = sum < 0 ? sum * -1 : sum;

  return `- ${formatAmount(posSum, currency)}`;
};

export const getIncomeSum = (sum = 0, currency = "₽") => {
  return `+ ${formatAmount(sum, currency)}`;
};
