import React from "react";

export const IconFile = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 20, height = 20, color = "#739B67" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.8333 8.45831H11.8C11.2585 8.4561 10.7401 8.23888 10.3587 7.85443C9.97742 7.46998 9.76444 6.94979 9.76666 6.40831V2.44164C9.76666 2.21152 9.58011 2.02498 9.34999 2.02498H7.12499C5.28404 2.02498 3.79166 3.51736 3.79166 5.35831V14.6416C3.79166 16.4826 5.28404 17.975 7.12499 17.975H12.875C13.759 17.975 14.6069 17.6238 15.232 16.9987C15.8571 16.3735 16.2083 15.5257 16.2083 14.6416V8.87498C16.2056 8.66165 16.0452 8.48339 15.8333 8.45831Z"
        fill={color}
      />
      <path
        d="M11.875 7.54164H15.7333C15.939 7.5586 16.1314 7.43841 16.2064 7.24612C16.2814 7.05382 16.2212 6.8351 16.0583 6.70831L11.4333 2.16664C11.3058 2.00178 11.0844 1.94147 10.8909 2.01888C10.6974 2.09629 10.5786 2.29264 10.6 2.49998V6.30831C10.5998 6.64276 10.7354 6.96295 10.9758 7.19548C11.2162 7.42801 11.5407 7.55294 11.875 7.54164Z"
        fill={color}
      />
    </svg>
  );
};
