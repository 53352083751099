import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as d3 from "d3";

import "./HistoryChart.css";

import theme from "theme";
import {
  ChartGroupKey,
  D3Event,
  DataElement,
  GroupColor,
  GroupOpacityColor,
  HistoryChartProps,
} from "./HistoryChart.types";
import {
  BAR_COLORS_ORDERED,
  BAR_HEIGHT,
  BAR_WIDTH,
  CHART_GROUP_KEYS,
  DEFAULT_WIDTH,
} from "./HistoryChart.consts";
import moment from "moment";
import { RU_LOCALE } from "constants/chart";
import React from "react";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  getExpenseSum,
  getIncomeSum,
} from "molecules/HistoryChartHeader/HistoryChartHeader.utils";
import { shortId } from "./HistoryChart.utils";

d3.timeFormatDefaultLocale(RU_LOCALE);

export const HistoryChart: React.FC<HistoryChartProps> = React.memo(
  ({
    data,
    expenseSum,
    incomeSum,
    containerRef,
    isMobile,
    onSelect,
    selectedDate,
    chartType,
    currencySign = "₽",
    defaultWidth = DEFAULT_WIDTH,
    barWidth = BAR_WIDTH,
    barHeight = BAR_HEIGHT,
  }) => {
    const [width, setWidth] = useState(defaultWidth);
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);

    const loaded = useRef<null | boolean>(null);
    const prevSelectItemId = useRef<string | null>(null);

    const selectedItemId = selectedDate || hoveredItemId;

    const id = shortId();
    const isMoreThanMonth =
      moment(new Date(data[0].date!)).diff(
        moment(new Date(data[data.length - 1].date!)),
        "month"
      ) !== 0;

    const margin = {
      top: isMobile ? 5 : 10,
      right: isMobile ? 16 : 20,
      bottom: 16,
      left: isMobile ? 16 : 20,
    };
    const height = barHeight;

    const handleClickOutside = useCallback(() => {
      onSelect?.(null);
    }, [onSelect]);
    const ref = useOutsideClick(handleClickOutside);

    const stack = useMemo(
      () =>
        d3
          .stack()
          .keys(CHART_GROUP_KEYS)
          .order(d3.stackOrderNone)
          .offset(d3.stackOffsetNone),
      []
    );
    // @ts-expect-error property date is not number type
    const series = useMemo(() => stack(data), [data, stack]);

    const xScale = useMemo(
      () =>
        d3.scaleUtc(
          [new Date(data[0].date!), new Date(data[data.length - 1].date!)],
          [barWidth / 2, width - barWidth / 2]
        ),
      [data, width, barWidth]
    );
    const yScale = useMemo(
      () => d3.scaleLinear().rangeRound([barHeight, 0]).domain([0, 100]),
      [barHeight]
    );

    const xAxisArea = useCallback(
      (ref: SVGGElement) => {
        const xAxis = d3.axisBottom(xScale);
        xAxis.tickFormat((d) => d3.timeFormat("%d")(d as Date));
        xAxis.ticks(data.length);

        if (data.length < 4) {
          xAxis.tickValues(data.map((el) => new Date(el.date!)));
        }

        const xAxisSelected = d3.select(ref);

        xAxisSelected.call(xAxis);
        xAxisSelected.selectAll(".domain").remove().exit();
        xAxisSelected.selectAll(".tick").selectAll("line").remove();
        xAxisSelected
          .selectAll("text")
          .attr("fill", theme.primary.gray?.[900] || "#454A3F")
          .attr("font-size", isMobile ? 9 : 11)
          .attr("font-weight", 500);

        xAxisSelected;
      },
      [data, xScale, isMobile]
    );

    const xAxisAreaMonths = useCallback(
      (ref: SVGGElement) => {
        const xAxis = d3.axisBottom(
          d3.scaleUtc(
            [new Date(data[0].date!), new Date(data[data.length - 1].date!)],
            [barWidth / 2, width - Math.max(barWidth, 15) - 25]
          )
        );
        xAxis.tickFormat((d) => d3.timeFormat("%B")(d as Date));

        xAxis.ticks(data.length / 30);
        xAxis.tickSize(3).offset(18);

        const xAxisSelected = d3.select(ref);

        xAxisSelected.call(xAxis);
        xAxisSelected.selectAll(".domain").remove().exit();
        xAxisSelected.selectAll(".tick").selectAll("line").remove();
        xAxisSelected
          .selectAll("text")
          .attr("fill", theme.primary.gray?.[900] || "#454A3F")
          .attr("font-size", isMobile ? 9 : 11)
          .attr("font-weight", 500);

        xAxisSelected;
      },
      [data, width, isMobile, barWidth]
    );

    d3.selectAll("#root").selectAll(`.${id}__chart-tooltip`).remove().exit();

    d3.selectAll("#root")
      .append("div")
      .attr("class", `${id}__chart-tooltip history-chart__tooltip`)
      .style("position", "absolute")
      .style("min-width", "120px")
      .style("z-index", "100000")
      .style("visibility", "hidden")
      .style("background-color", "rgba(255, 255, 255, 0.72)")
      .style("backdrop-filter", "blur(11.3px)")
      .style("border-radius", "8px")
      .style("padding", "4px 12px")
      .style("transform", "translate(0, -50%)")
      .style(
        "border",
        "border: 1px solid border-image-source: linear-gradient(101.21deg, rgba(255, 255, 255, 0.54) 8.91%, rgba(255, 255, 255, 0) 58.27%)"
      );

    const getGroupColor = useMemo(
      () =>
        d3
          .scaleOrdinal<string>()
          .domain(CHART_GROUP_KEYS)
          .range([GroupColor.Orange, GroupColor.Green, GroupColor.White]),
      []
    );
    const getGroupOpacityColor = useMemo(
      () =>
        d3
          .scaleOrdinal<string>()
          .domain(CHART_GROUP_KEYS)
          .range([
            GroupOpacityColor.Orange,
            GroupOpacityColor.Green,
            GroupOpacityColor.White,
          ]),
      []
    );

    const onSelectBar = useCallback(
      (e: unknown, d: DataElement) => {
        onSelect?.(String(d.data.date));
      },
      [onSelect]
    );

    const onMouseOver = useCallback(
      (e: unknown, d: DataElement) => {
        setHoveredItemId(String(d.data.date));

        d3.select(`.${id}__chart-tooltip`)
          .transition()
          .style("visibility", "visible");
      },
      [id]
    );

    const onMouseMove = useCallback(
      (
        e: MouseEvent,
        d: d3.SeriesPoint<{
          [key: string]: number;
        }>
      ) => {
        const tooltip = d3.select(`.${id}__chart-tooltip`);
        const tooltipWidth = (
          tooltip.node() as Element
        )?.getBoundingClientRect().width;
        let tooltipPosX = e.pageX;

        const expenseSum =
          chartType !== "income"
            ? getExpenseSum(d.data.expenseSum, currencySign)
            : "";
        const incomeSum =
          chartType !== "expense"
            ? getIncomeSum(d.data.incomeSum, currencySign)
            : "";
        const separator = !chartType ? " / " : "";

        tooltip.html(
          `<span class="tooltip__text">${expenseSum} ${separator}</span> <span class="tooltip__text tooltip__text_primary">${incomeSum}</span>`
        );

        if (e.pageX + tooltipWidth > window.innerWidth) {
          tooltipPosX -= tooltipWidth;
        }

        return tooltip
          .style("top", e.pageY - 30 + "px")
          .style("left", tooltipPosX + "px");
      },
      [id, currencySign, chartType]
    );

    const onMouseOut = useCallback(
      (e: D3Event<MouseEvent, SVGGElement>, d: DataElement) => {
        if (
          (e.relatedTarget as Element)?.classList.value ===
          (e.target as Element)?.classList.value
        )
          return;

        setHoveredItemId(null);

        return d3
          .select(`.${id}__chart-tooltip`)
          .transition()
          .style("visibility", "hidden");
      },
      [id]
    );

    const onScroll = useCallback(
      (e: WheelEvent) => {
        if (!ref.current) return;

        if (ref.current.getBoundingClientRect().width === width) return;

        e.preventDefault();

        if (e.deltaY > 0) ref.current.scrollLeft += 100;
        else ref.current.scrollLeft -= 100;
      },
      [width, ref]
    );

    useEffect(() => {
      const wrapper = containerRef.current;

      if (!wrapper) return;

      wrapper.addEventListener("wheel", onScroll, {
        passive: false,
      });

      return () => wrapper?.removeEventListener("wheel", onScroll);
    }, [containerRef, onScroll]);

    useLayoutEffect(() => {
      const group = d3
        .select(`.${id}__chart-container`)
        .selectAll(`.${id}__chart-group`)
        .remove()
        .exit()
        .data(series)
        .enter()
        .append("g")
        .attr("class", (d) => `${id}__chart-group ${d.key}`)
        .attr("pointer-events", "none")
        .sort((d) => {
          if (d.key === ChartGroupKey.RemainValue) return -1;
          if (d.key === ChartGroupKey.IncomeValue) return -1;

          return 1;
        });

      const rects = group
        .selectAll("rect")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("rect");

      d3.selectAll(`.${ChartGroupKey.RemainValue}`)
        .selectAll("rect")
        .data((d: any) => {
          return d;
        })
        .attr("y", function (d) {
          return yScale(100);
        })
        .attr("height", barHeight);

      d3.selectAll(`.${ChartGroupKey.IncomeValue}`)
        .selectAll("rect")
        .data((d: any) => {
          return d;
        })
        .attr("y", function (d) {
          return yScale(0);
        })
        .attr("height", 0)
        .transition()
        .duration(500)
        .attr("y", function (d: any) {
          return yScale(d.data.incomeValue);
        })
        .attr("height", (d: any) => {
          return barHeight - yScale(d.data.incomeValue);
        });

      d3.selectAll(`.${ChartGroupKey.ExpenseValue}`)
        .selectAll("rect")
        .data((d: any) => {
          return d;
        })
        .attr("y", function (d) {
          return yScale(0);
        })
        .attr("height", 0)
        .transition()
        .duration(500)
        .attr("y", function (d: any) {
          return yScale(d.data.expenseValue);
        })
        .attr("height", (d: any) => {
          return barHeight - yScale(d.data.expenseValue);
        });

      rects
        .attr("x", (d) => xScale(new Date(d.data.date)) - barWidth / 2)
        .attr("width", barWidth)
        .attr("cursor", "pointer")
        .attr("rx", (d) => {
          const isRemainGroup = d[1] >= 100;

          if (isRemainGroup) return 4;

          return 3;
        })
        .attr("class", function (d) {
          return `${id}__${d.data.date}`.replace(/\s/g, "") + " rect";
        })
        .on("mouseover", onMouseOver)
        .on("mousemove ", onMouseMove)
        .on("mouseout", onMouseOut)
        .on("click", onSelectBar);

      group
        .transition()
        .attr("fill", (d) => {
          if (d.key === "incomeValue" || d.key === "expenseValue")
            return "#E8ECE3";

          return getGroupColor(d.key);
        })
        .transition()
        .ease(d3.easePolyIn)
        .duration(350)
        .attr("fill", (d) => getGroupColor(d.key))
        .on("end", () => {
          group.attr("pointer-events", "auto");
          loaded.current = true;
        });
    }, [
      getGroupColor,
      id,
      xScale,
      yScale,
      series,
      barHeight,
      barWidth,
      onMouseOver,
      onMouseMove,
      onMouseOut,
      onSelectBar,
    ]);

    useLayoutEffect(() => {
      const calculateSize = (ref: React.RefObject<HTMLDivElement>) => {
        let barPadding = 9.3;

        if (window.innerWidth < 375) {
          barPadding = 6.3;
        }
        if (window.innerWidth < 325) {
          barPadding = 5.8;
        }

        if (ref.current) {
          const size = ref.current.getBoundingClientRect();
          const newWidth = size.width - margin.left - margin.right;

          setWidth(
            Math.round(
              Math.max(data.length * (barWidth + barPadding), newWidth)
            )
          );
        }
      };

      const handleResize = () => {
        calculateSize(containerRef);
      };

      calculateSize(containerRef);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [containerRef, data, barWidth, margin.left, margin.right]);

    useEffect(() => {
      if (!loaded.current) return;

      CHART_GROUP_KEYS.forEach((key) => {
        d3.selectAll(`.${key}`)
          .transition()
          .attr(
            "fill",
            selectedItemId ? getGroupOpacityColor(key) : getGroupColor(key)
          );
      });

      d3.selectAll(`.${id}__${prevSelectItemId.current}`)
        .transition()
        .duration(175)
        .attr("filter", "")
        .transition()
        .duration(175)
        .attr("fill", "");

      if (selectedItemId) {
        prevSelectItemId.current = selectedItemId;

        const selectedItem = data.find((el) => el.date === selectedItemId);

        d3.select(".history-chart-header__expenseSum")
          .transition()
          .delay(300)
          .text(getExpenseSum(selectedItem?.expenseSum || 0));
        d3.select(".history-chart-header__incomeSum")
          .transition()
          .delay(300)
          .text(getIncomeSum(selectedItem?.incomeSum || 0));

        d3.selectAll(`.${id}__${selectedItemId}`)
          .nodes()
          .forEach((node, index) => {
            if (node) {
              d3.select(node)
                .transition()
                .attr("fill", BAR_COLORS_ORDERED[index])
                .transition()
                .duration(200)
                .attr(
                  "filter",
                  !index
                    ? "drop-shadow(0px 4px 12px rgba(100, 115, 95, 0.3))"
                    : "unset"
                );
            }
          });
      }

      return () => {
        d3.select(".history-chart-header__expenseSum")
          .transition()
          .delay(500)
          .text(getExpenseSum(expenseSum));
        d3.select(".history-chart-header__incomeSum")
          .transition()
          .delay(500)
          .text(getIncomeSum(incomeSum));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemId, getGroupOpacityColor, getGroupColor, id]);

    return (
      <div>
        <div style={{ position: "relative" }}>
          <div ref={ref} style={{ width: "100%", overflow: "scroll" }}>
            <svg
              height={
                height + margin.top + margin.bottom + (isMoreThanMonth ? 15 : 0)
              }
              width={width}
            >
              <g
                ref={xAxisArea}
                style={{ transform: `translate(0, ${height}px)` }}
              />

              {isMoreThanMonth && (
                <g
                  ref={xAxisAreaMonths}
                  width={width}
                  style={{
                    transform: `translate(0px, ${height + 20}px)`,
                  }}
                />
              )}
              <g className={`${id}__chart-container`} />
            </svg>
          </div>
        </div>
      </div>
    );
  }
);

HistoryChart.displayName = "HistoryChart";
