import React from "react";

interface IconMirProps extends React.SVGProps<SVGSVGElement> {}

export const IconMir = ({ color = "white" }: IconMirProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="22"
      viewBox="0 0 81 22"
      fill="none"
    >
      <path
        d="M0 0.00358015H7.59861C8.2894 0.00358015 10.3617 -0.225549 11.2828 2.98226C11.9736 5.04443 12.8946 8.25224 14.2762 13.064H14.7367C16.1183 8.02311 17.2696 4.58617 17.7301 2.98226C18.6511 -0.225549 20.9537 0.00358015 21.8748 0.00358015H29.0129V22H21.6445V8.93963H21.184L17.2696 22H11.7433L7.82887 8.93963H7.13809V22H0M32.0063 0.00358015H39.3746V13.064H40.0654L44.9009 2.29487C45.8219 0.23271 47.8943 0.00358015 47.8943 0.00358015H54.8021V22H47.4338V8.93963H46.9732L42.1378 19.7087C41.2167 21.7709 38.9141 22 38.9141 22H32.0063M64.7033 15.3552V22H57.7955V10.5435H80.3611C79.44 13.2931 76.2164 15.3552 72.5322 15.3552"
        fill={color}
      />
      <path
        d="M80.8222 9.64467C81.7432 5.52034 78.9801 0.479492 72.9933 0.479492H57.3356C57.7961 5.29121 61.9408 9.64467 66.3157 9.64467"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3496_2150"
          x1="78.0591"
          y1="-2.49919"
          x2="59.6382"
          y2="-2.49919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};
