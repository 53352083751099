import styled from "@emotion/styled/macro";

export interface Props {
  disabled?: boolean;
}

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 12px;
  background: var(--main-color-bg-widgets);
  cursor: pointer;
  opacity: ${(props: Props) => (props.disabled ? "0.6" : "1")};

  &:hover {
    background-color: var(--brand-color-disabled);
  }

  @media (max-width: 550px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 32px;
  scroll-behavior: smooth;
  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 18px;
  }
`;

export const Title = styled.span`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: var(--main-color-text-title);
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 28px;
  }
`;
