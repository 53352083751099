import { AddressOperationActionType, AddressResponse } from "api/account";
import { FilterState } from "store/slices/profile/slice";

export const matchActions = (
  filter: FilterState,
  actions: AddressOperationActionType[]
): boolean => {
  const searchActions = [] as AddressOperationActionType[][];

  if (filter.cashIn) {
    const cashActions: AddressOperationActionType[] = [];
    cashActions.push(AddressOperationActionType.IncomingWithoutFee);
    cashActions.push(AddressOperationActionType.IncomingWithFee);
    // if (!filter.freeFee) {
    //   cashActions.push(AddressOperationActionType.IncomingWithFee);
    // }
    searchActions.push(cashActions);
  }

  if (filter.RUB) {
    const rubActions: AddressOperationActionType[] = [];
    rubActions.push(AddressOperationActionType.WithdrawRubWithoutFee);
    rubActions.push(AddressOperationActionType.WithdrawRubWithFee);
    // if (!filter.freeFee) {
    //   rubActions.push(AddressOperationActionType.WithdrawRubWithFee);
    // }
    searchActions.push(rubActions);
  }

  if (filter.TJS) {
    const tjsActions: AddressOperationActionType[] = [];
    tjsActions.push(AddressOperationActionType.WithdrawTjsWithoutFee);
    tjsActions.push(AddressOperationActionType.WithdrawTjsWithFee);
    // if (!filter.freeFee) {
    //   tjsActions.push(AddressOperationActionType.WithdrawTjsWithFee);
    // }
    searchActions.push(tjsActions);
  }

  if (filter.KGS) {
    const kgsActions: AddressOperationActionType[] = [];
    kgsActions.push(AddressOperationActionType.WithdrawKgsWithoutFee);
    kgsActions.push(AddressOperationActionType.WithdrawKgsWithFee);
    // if (!filter.freeFee) {
    //   kgsActions.push(AddressOperationActionType.WithdrawKgsWithFee);
    // }
    searchActions.push(kgsActions);
  }

  if (filter.KZT) {
    const kztActions: AddressOperationActionType[] = [];
    kztActions.push(AddressOperationActionType.WithdrawKztWithoutFee);
    kztActions.push(AddressOperationActionType.WithdrawKztWithFee);
    // if (!filter.freeFee) {
    //   kztActions.push(AddressOperationActionType.WithdrawKztWithFee);
    // }
    searchActions.push(kztActions);
  }

  if (filter.AMD) {
    const amdActions: AddressOperationActionType[] = [];
    amdActions.push(AddressOperationActionType.WithdrawAmdWithoutFee);
    amdActions.push(AddressOperationActionType.WithdrawAmdWithFee);
    // if (!filter.freeFee) {
    //   amdActions.push(AddressOperationActionType.WithdrawAmdWithFee);
    // }
    searchActions.push(amdActions);
  }

  return searchActions.every((currAction) =>
    currAction.some((value) => actions.includes(value))
  );
};

export const matchWorkingTime = (
  filter: FilterState,
  workingTime: string[]
) => {
  return !filter.allTime || workingTime.includes("FULLDAY");
};

export const matchSearch = (
  filter: FilterState,
  address: string,
  country: string
) => {
  if (!filter.search) {
    return true;
  }

  const tokens = filter.search.trim().split(/\s/);

  return tokens.every(
    (value) =>
      address.toLowerCase().includes(value.toLowerCase()) ||
      country.toLowerCase().includes(value.toLowerCase())
  );
};

export const matchAllTimeAndIncoming = (
  filter: FilterState,
  resultArr: AddressResponse[]
) => {
  return resultArr.filter((item) => {
    item.operations?.forEach((e) => {
      // const isIncoming =
      //   e.availableActions?.includes(
      //     AddressOperationActionType.IncomingWithFee
      //   ) ||
      //   e.availableActions?.includes(
      //     AddressOperationActionType.IncomingWithoutFee
      //   );
      // filter.freeFee
      //   ? e.availableActions?.includes(
      //       AddressOperationActionType.IncomingWithoutFee
      //     )
      //   :
      // e.availableActions?.includes(
      //   AddressOperationActionType.IncomingWithFee
      // ) ||
      //   e.availableActions?.includes(
      //     AddressOperationActionType.IncomingWithoutFee
      //   );

      // if (e.workingHour === "FULLDAY" && isIncoming) {
      if (e.workingHour === "FULLDAY") {
        return item;
      }
    });
  });
};
