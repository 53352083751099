import React from "react";

interface IconSquareProps extends React.SVGProps<SVGSVGElement> {
  size?: string;
}

export const IconSquare = (props: IconSquareProps) => {
  const { size = "20px", color = "white" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.67 -0.000976562H14.34C17.73 -0.000976562 20 2.37902 20 5.91902V14.09C20 17.62 17.73 19.999 14.34 19.999H5.67C2.28 19.999 0 17.62 0 14.09V5.91902C0 2.37902 2.28 -0.000976562 5.67 -0.000976562ZM13.01 12.999C13.35 12.66 13.35 12.11 13.01 11.77L11.23 9.99002L13.01 8.20902C13.35 7.87002 13.35 7.31002 13.01 6.97002C12.67 6.62902 12.12 6.62902 11.77 6.97002L10 8.74902L8.22 6.97002C7.87 6.62902 7.32 6.62902 6.98 6.97002C6.64 7.31002 6.64 7.87002 6.98 8.20902L8.76 9.99002L6.98 11.76C6.64 12.11 6.64 12.66 6.98 12.999C7.15 13.169 7.38 13.26 7.6 13.26C7.83 13.26 8.05 13.169 8.22 12.999L10 11.23L11.78 12.999C11.95 13.18 12.17 13.26 12.39 13.26C12.62 13.26 12.84 13.169 13.01 12.999Z"
        fill={color}
      />
    </svg>
  );
};
