import { Box } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult";
import { ErrorMessage } from "store/slices/cardsSlice";
import { IconUnSuccessStatusChange } from "atoms/IconUnSuccessStatusChange/IconUnSuccessStatusChange";
import { IconRefresh } from "atoms/IconRefresh/IconRefresh";

type Props = {
  onClick: () => void;
  payload: ErrorMessage;
};

export const UnSuccessStatusChange = ({ onClick, payload }: Props) => {
  const { title, subtitle } = payload;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
    >
      <Box marginTop="80px" height="100%" width="100%">
        <NotificationResult
          title={title as string}
          titleSize="text_12"
          subtitleSize="text_5"
          subtitle={subtitle}
          icon={<IconUnSuccessStatusChange />}
          height="100%"
          buttonIcon={
            <span style={{ display: "inline-block", marginRight: "8px" }}>
              <IconRefresh />
            </span>
          }
          buttons={[
            {
              name: "Обновить",
              variant: "primary",
              onClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
