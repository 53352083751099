import React from "react";
import { Box, Typography } from "@mui/material";

const ClickableHeader = ({ onBack, title = true }) => {
  return (
    <Box>
      <Box
        maxWidth={816}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "12px",
          px: "16px",
          margin: "0 auto",
          boxSizing: "border-box",
        }}
      >
        <Box
          onClick={onBack}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 17L2 11.5L7 6"
              stroke="#454A3F"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        {title && (
          <Typography
            sx={{
              width: "100%",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "28px",
              textAlign: "center",
              color: "var(--main-color-text-title)",
            }}
          >
            Чат
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ClickableHeader;
