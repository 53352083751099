import { Switch, SwitchProps, Typography, Box } from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { setStatement } from "store/slices/cardsSlice";
import { profileSelector } from "store";
import { getCardsShow } from "store/slices/profile/asyncThunks";
import { profileActions } from "store/slices/profile";
import { IconCardMini } from "atoms/IconCardMini/IconCardMini";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--brand-color-primary)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "var(--brand-color-primary)",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "var(--main-color-border-icon)",
    opacity: 1,
  },
}));

export const ProfileToggle = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(profileSelector);

  const showBlockedCards = () => {
    dispatch(getCardsShow({ all: !user?.showAllCards }))
      //@ts-ignore
      .unwrap()
      .then(() => {
        dispatch(profileActions.setIsllCardsShow());
        dispatch(
          setStatement({
            data: {
              accId: "%",
              forceRefresh: false,
            },
          })
        );
      });
  };

  return (
    <Box
      sx={{
        borderRadius: 15,
        backgroundColor: "var(--main-color-bg-widgets)",
      }}
      p={{ xs: 12, xl: 12 }}
      display="flex"
      gap={12}
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 11,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 40,
          height: 40,
          backgroundColor: "#FFFFFF",
        }}
      >
        <IconCardMini width={20} height={20} />
      </Box>
      <Typography variant="text_22" fontWeight={500} color="gray.b800" flex={1}>
        Показать закрытые карты
      </Typography>
      <IOSSwitch
        sx={{ m: 1 }}
        checked={user?.showAllCards}
        onChange={showBlockedCards}
      />
    </Box>
  );
};
