import { CheckBlockType } from "components/CheckBlock/CheckBlock";

export const validateUpperLower = (string: string, type: CheckBlockType) => {
  if (type === "login") {
    const hasLatinLetter = /[a-zA-Z]/.test(string);
    const hasDigitOrSpecialChar = /[0-9+:_().!?-]/.test(string);

    return hasLatinLetter && hasDigitOrSpecialChar;
  } else {
    return (
      /[A-Z]/.test(string) &&
      /[a-z]/.test(string) &&
      !/[а-яА-ЯёЁ]/u.test(string)
    );
  }
};
